@use "src/constants/styles/mixins.scss";

.digitalServicesContainer {
  background-color: #f2f2f2;

  @include mixins.respond(desktop) {
    margin-top: 80px;
  }

  @include mixins.respond(lgdesktop) {
    margin-top: 80px;
  }

  @include mixins.respond(tablet) {
    margin-top: 60px;
  }

  @include mixins.respond(mobile) {
    padding-bottom: 32px;
  }

  .digitalFlexContainer {
    max-width: 90vw;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mixins.respond(tablet) {
      max-width: 95vw;
    }

    @include mixins.respond(mobile) {
      flex-direction: column;
    }

    .digitalServicesContentContainer {
      width: 55%;

      @include mixins.respond(tablet) {
        width: 100%;
        padding-bottom: 34px;
        // padding: 22px 0;
      }

      // padding: 16px 100px;

      h2 {
        text-align: center;
        font-family: "DM Sans", sans-serif;
        font-weight: bold;
        font-size: 46px;
        color: #0f2137;
        padding: 20px 0;

        @include mixins.respond(tablet) {
          // margin-top: 28px;
          font-size: 32px;
        }
      }

      .buttons-container {
        width: 64%;
        margin: 0 auto 24px auto;
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        // align-content: center;
        // place-content: center;
        gap: 21px 31px;

        @include mixins.respond(desktop) {
          width: 50%;
          margin: 0 auto;
        }

        @include mixins.respond(lgdesktop) {
          width: 45%;
          margin: 0 auto;
        }

        @include mixins.respond(tablet) {
          width: 80%;
          margin: 0 auto;
          // align-content: center;
          // place-content: center;
          gap: 20px 20px;
        }

        @include mixins.respond(mobile) {
          width: 85%;
          margin: 0 auto;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          justify-items: center;
          // gap: 10px;
        }

        .button {
          width: 136px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          gap: 14px 0;
          padding: 20px 16px;
          background-color: #fff;
          border-radius: 10px;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
            rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
          cursor: pointer;

          @include mixins.respond(tablet) {
            width: 180px;
          }

          @include mixins.respond(mobile) {
            width: 142px;
          }

          &:hover {
            img {
              animation: digiBtnAnimation 1.5s ease 250ms;
            }

            @keyframes digiBtnAnimation {
              30% {
                transform: scale(1.2);
              }
              40%,
              60% {
                transform: rotate(-20deg) scale(1.2);
              }
              50% {
                transform: rotate(20deg) scale(1.2);
              }
              70% {
                transform: rotate(0deg) scale(1.2);
              }
              100% {
                transform: scale(1);
              }
            }
          }

          img {
            height: 52px;
            width: auto;
          }

          p {
            text-align: center;
            font-size: 18px;
            color: #0f2137;
            font-weight: 500;
          }
        }
      }
    }

    .digitalImage {
      width: 40%;

      @include mixins.respond(desktop) {
        width: 40%;
      }

      @include mixins.respond(lgdesktop) {
        width: 40%;
      }

      @include mixins.respond(tablet) {
        display: none;
      }

      @include mixins.respond(mobile) {
        width: 95%;
        display: block;
      }

      .sliderContainer {
        position: relative;
        width: 100%;
        margin: auto;

        .cus-back {
          position: absolute;
          top: 50%;
          left: -10px;
          transform: translateY(-50%);
          background-color: transparent !important;
          border: none;
          border-radius: 50%;

          @include mixins.respond(tablet) {
            display: none;
          }

          .btn-left {
            color: #dd1134;
            height: 32px;
            width: 32px;

            &:hover {
              color: #b60b27;
            }
          }
        }

        .cus-next {
          position: absolute;
          top: 50%;
          right: -15px;
          transform: translateY(-50%);
          background-color: transparent !important;
          border: none;
          border-radius: 50%;

          @include mixins.respond(tablet) {
            display: none;
          }

          @include mixins.respond(desktop) {
            right: 30px;
          }

          // @include mixins.respond(lgdesktop) {
          //     right: 0px!important;
          // }

          .btn-right {
            color: #dd1134;
            height: 32px;
            width: 32px;

            &:hover {
              color: #b60b27;
            }
          }
        }
      }
    }
  }
}
