@use "src/constants/styles/mixins.scss";

.information-section-container {
  max-width: 90vw;
  margin: 18px auto;

  .section-heading {
    font-family: "DM Sans", sans-serif;
    font-weight: bold;
    font-size: 46px;
    color: #0f2137;
    padding: 14px 0;

    @include mixins.respond(tablet) {
      font-size: 32px;
      padding: 7px 0;
    }
  }

  .primaryContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mixins.respond(tablet) {
      flex-direction: column-reverse;
    }

    .left-container {
      width: 70%;

      @include mixins.respond(tablet) {
        width: 100%;
      }

      .first-flex-container {
        // width: 70%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding-bottom: 26px;

        @include mixins.respond(mobile) {
          flex-direction: column;
        }

        .flex-item {
          width: 30%;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          gap: 0 12px;
          cursor: pointer;

          @include mixins.respond(mobile) {
            width: 100%;
          }

          img {
            height: 72px;
            width: auto;
          }

          .sub-flex {
            // display: flex;
            // flex-direction: column;
            // gap: 10px 0;

            h3 {
              font-family: "Roboto", sans-serif;
              font-size: 20px;
              font-weight: 700;
              color: #0f2137;
              padding-top: 16px;
              padding-bottom: 8px;
            }

            p {
              font-family: "Roboto", sans-serif;
              font-size: 16px;
              font-weight: 500;
              color: #0f2137;
              letter-spacing: 0.5px;
              line-height: 150%;
            }
          }
        }
      }

      .second-flex-container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .oneFlexContainer {
          // width: 70%;
          .aboutContainer {
            padding: 22px 10px;
            h4 {
              font-family: "Roboto", sans-serif;
              font-size: 20px;
              font-weight: 700;
              color: #0f2137;
              padding: 18px 0;
            }
            .flex-container {
              padding-left: 10px;
              display: flex;
              align-items: flex-start;
              justify-content: space-between;
              gap: 0 2rem;

              @include mixins.respond(tablet) {
                gap: 0 12px;
              }

              @include mixins.respond(mobile) {
                flex-direction: column;
                gap: 16px;
              }

              a {
                font-family: "Roboto", sans-serif;
                font-size: 17px;
                color: #0f2137;
                // font-weight: 500;
                text-decoration: none;
                transition: transform 250ms ease-in-out;

                &:hover {
                  // text-decoration: underline;
                  // font-weight: 500;
                  transform: translateY(-2px);
                }
              }
            }
          }

          .followContainer {
            padding: 22px 10px;
            h4 {
              font-family: "Roboto", sans-serif;
              font-size: 20px;
              font-weight: 700;
              color: #0f2137;
              padding: 18px 0;
            }

            .flex-container {
              padding-left: 10px;
              display: flex;
              align-items: flex-start;
              justify-content: space-between;

              @include mixins.respond(mobile) {
                flex-direction: column;
                justify-content: flex-start;
                gap: 16px;
              }

              .btn-container {
                display: flex;
                align-items: center;
                justify-content: left;
                gap: 0 6px;
                cursor: pointer;
                transition: transform 250ms ease-in-out;

                &:hover {
                  transform: translateY(-2px);
                }

                img {
                  height: 24px;
                  width: auto;
                }

                .tik {
                  height: 20px !important;
                  width: auto !important;
                }

                p {
                  font-family: "Roboto", sans-serif;
                  font-size: 17px;
                  color: #0f2137;
                }
              }
            }
          }
        }

        // .twoFlexContainer{

        // }
      }
    }

    .officers-container {
      position: relative;
      max-width: 25%;

      .digital-inqueryss {
        position: absolute;
        top: -80px;
        border: 1px solid #ddd;
        border-radius: 10px;
        width: 100%;

        h2 {
          font-size: 20px;
          background-color: rgb(0, 51, 153);
          color: rgb(255, 255, 255);
          border-radius: 10px;
          padding: 10px;
        }
        p {
          align-self: center;
          color: #039;
          font-family: Roboto, sans-serif;
          font-size: 18px;
          font-weight: 600;
          margin: 7px 0;
          text-align: center;
        }
        div {
          align-items: center;
          display: flex;
          gap: 8px;
          justify-content: flex-start;
          margin-left: 10px;
          svg {
            color: #039;
            font-size: 17px;
          }
        }
      }
      .informationsssss {
        position: relative;
        top: 80px;
        margin-bottom: 90px;

        // .MuiButtonBase-root {
        //   min-height: 40px !important;
        // }
      }

      @include mixins.respond(tablet) {
        max-width: 60%;
        .digital-inqueryss {
          position: relative;
        }
      }

      @include mixins.respond(mobile) {
        max-width: 100%;
        .digital-inqueryss {
          position: relative;
          top: 0px;
        }
        .informationsssss {
          top: 10px;
        }
      }

      .css-1yn1z7j-MuiPaper-root-MuiAccordion-root.Mui-expanded {
        margin: 2px 0;
      }

      // .css-1yn1z7j-MuiPaper-root-MuiAccordion-root{
      //     margin: 6px 0;
      // }

      .officer-item-container {
        margin: 16px 0;
        display: flex;
        align-items: flex-start;
        justify-content: left;
        gap: 0 22px;

        img {
          height: 160px;
          width: auto;
        }

        .info-container {
          h4 {
            font-size: 18px;
            font-weight: 600;
            color: #0f2137;
            margin-bottom: 6px;
          }

          .info {
            p {
              font-size: 17px;
              margin-bottom: 8px;
              overflow-wrap: break-word;
            }

            .phone-flex {
              display: flex;
              align-items: center;
              justify-content: left;
              gap: 0 10px;
            }
          }
        }
      }
    }
  }
}

.information-accordion-details-container {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  img {
    align-self: center;
    margin: 0 auto;
    width: 140px;
    height: auto;
    border-radius: 50%;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
      rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }

  .officer-name {
    margin: 14px 0 7px 0;
    align-self: center;
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: 700;
    color: #003399;
  }
  .dessss {
    align-self: center;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
  }

  .digital-name {
    margin: 7px 0 7px 0;
    text-align: center;
    align-self: center;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: #003399;
  }

  div {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    svg {
      color: #003399;
      font-size: 17px;
    }

    p {
      margin: 0;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 500;
      color: #0f2137;
    }
  }
}
