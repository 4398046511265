@use "src/constants/styles/mixins.scss";

.serviceParent {
  position: relative;

  height: 630px;

  @include mixins.respond(tablet) {
    height: auto;
  }

  @include mixins.respond(desktop) {
    height: 400px;
  }

  @include mixins.respond(lgdesktop) {
    height: 400px;
  }

  .serviceWrapper {
    position: absolute;
    width: 100%;
    top: -45vh;
    // top: -18vh;
    // bottom: -15vh;
    @include mixins.respond(tablet) {
      position: relative;
      top: 24px;
    }

    .serviceMainContainer {
      // top: 90vh;
      max-width: 90vw;
      margin: 0 auto;
      padding: 52px 38px;
      // height: 100%;
      background-color: #fff;
      // display: flex;
      // align-items: flex-start;
      // justify-content: space-between;
      // gap: 0 32px;
      display: grid;
      grid-auto-flow: column;
      // align-content: space-between;
      place-content: space-around;
      gap: 0 38px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
        rgba(0, 0, 0, 0.23) 0px 3px 6px;

      @include mixins.respond(desktop) {
        max-width: 75vw;
      }

      @include mixins.respond(lgdesktop) {
        max-width: 70vw;
      }

      @include mixins.respond(tablet) {
        max-width: 95vw;
        padding: 38px 38px 70px 38px;
        grid-auto-flow: row;
        gap: 0 26px;
      }

      @include mixins.respond(mobile) {
        padding: 28px 28px 50px 28px;
      }

      .serviceLeftContainer {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 32px;
        height: 100%;

        @include mixins.respond(tablet) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
          gap: 16px;
        }

        @include mixins.respond(mobile) {
          grid-template-columns: repeat(1, minmax(0, 1fr));
          gap: 16px;
        }

        .serviceItemContainer {
          padding: 24px;
          border: 1px solid #00000033;
          border-radius: 15px;
          transition: transform 250ms ease-in-out;
          cursor: pointer;

          @include mixins.respond(tablet) {
            padding: 36px;
          }

          @include mixins.respond(mobile) {
            padding: 34px;
          }

          &:hover {
            transform: translateY(-6px);
            // box-shadow: 0px 4px 10px #72A0C1;
            // box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
            // border: none;
            box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
            transition-delay: 0.5s;

            img {
              animation: pulse 1.5s 250ms;
              transform-origin: center center;

              @keyframes pulse {
                0% {
                  transform: scale(1);
                }
                25% {
                  transform: scale(0.95);
                }
                35% {
                  transform: scale(0.87);
                }
                45% {
                  transform: scale(1.15);
                }
                55% {
                  transform: scale(0.87);
                }
                65% {
                  transform: scale(1.15);
                }
                75% {
                  transform: scale(1.08);
                }
                100% {
                  transform: scale(1);
                }
              }
            }
          }

          img {
            height: 70px;
            width: auto;
            margin: 4px;
          }
          h2 {
            font-size: 23px;
            font-weight: 400;
            color: #0a2252;
            margin: 8px 0 0 0;
          }

          .serviceItemList {
            width: 80%;
            margin: 14px auto 20px auto;

            .serviceItemListItem {
              // display: flex;
              // align-items: flex-start;
              // justify-content: flex-start;
              // gap: 0 8px;
              color: #0f2137;
              margin: 7px 0;
              font-size: 16px;
              font-weight: 500;
              letter-spacing: 0.2px;
              line-height: 1.4;

              .serviceListIcon {
                // flex-basis: 24px;
                // flex-grow: 0;
                flex-shrink: 0;
                transform: rotate(90deg);
                font-size: 10px;
                color: #003399;
              }

              .serviceListIconImg {
                flex-shrink: 0;
                height: 11px;
                width: auto;
                margin-top: 2px !important;
              }

              p {
                font-size: 18px;
              }
            }

            // .serviceItemList::marker{
            //     padding-left: 20px;
            // }
          }

          .double {
            align-self: flex-start !important;
            align-items: center !important;
            justify-content: space-between !important;
          }

          .ButtonContainer {
            align-self: flex-end;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 0 12px;

            .filled-btn {
              padding: 10px 12px;
              background-color: #dd1134;
              border: 2px solid #dd1134;
              border-radius: 5px;
              font-size: 14px;
              color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;

              // @include mixins.respond(mobile) {
              //     background-color: yellow;
              //   }
            }

            .outline-btn {
              box-sizing: border-box;
              -moz-box-sizing: border-box;
              -webkit-box-sizing: border-box;
              padding: 10px 12px;
              background-color: none;
              border: 2px solid #dd1134;
              border-radius: 5px;
              font-size: 14px;
              font-weight: 500;
              color: #dd1134;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            }
          }
        }
      }

      .serviceRightContainer {
        width: 260px;
        height: 100%;
        background-color: rgba(0, 51, 153, 0.6);
        border-radius: 10px;
        position: relative;

        @include mixins.respond(desktop) {
          width: 380px;
        }

        @include mixins.respond(lgdesktop) {
          width: 400px;
        }

        @include mixins.respond(tablet) {
          margin-top: 24px;
          width: 100%;
        }

        @include mixins.respond(mobile) {
          display: none;
          // margin-top: 22px;
          // width: 100%;
        }

        img {
          width: 100%;
          height: auto;
          //  position: relative;
          position: absolute;
          margin-top: 100px;
          bottom: 0px;

          @include mixins.respond(tablet) {
            display: none;
          }
        }

        h3 {
          color: #fff;
          font-size: 23px;
          font-weight: 500;
          max-width: 85%;
          margin: 42px auto 20px auto;
        }

        .btn-container {
          max-width: 85%;
          margin: 0 auto;
          //  height: auto;

          @include mixins.respond(tablet) {
            display: flex;
            align-items: center;
            justify-content: space-around;
            flex-wrap: wrap;
          }

          @include mixins.respond(mobile) {
            display: block;
          }

          .btn {
            display: flex;
            color: #fff;
            border: 2px solid #fff;
            border-radius: 8px;
            padding: 10px 16px;
            align-items: center;
            justify-content: space-between;
            margin: 16px 0;

            @include mixins.respond(tablet) {
              margin: 10px 0;
            }

            @include mixins.respond(mobile) {
              margin: 16px 0;
            }

            &:hover {
              cursor: pointer;
              box-shadow: 0px 4px 10px #72a0c1;
              // transition-delay: 0.3s;

              .icon {
                animation: digiBtnAnimation 1.5s ease 250ms;
              }

              @keyframes digiBtnAnimation {
                30% {
                  transform: scale(1.2);
                }
                40%,
                60% {
                  transform: rotate(-60deg) scale(1.2);
                }
                50% {
                  transform: rotate(60deg) scale(1.2);
                }
                70% {
                  transform: rotate(0deg) scale(1.2);
                }
                100% {
                  transform: scale(1);
                }
              }
            }

            p {
              font-size: 15px;
              font-weight: 400;
              letter-spacing: 1px;
            }
          }
        }
      }
    }

    .mobileServiceContainer {
      max-width: 90vw;
      margin: 0 auto;
      .mobileService {
        .serviceItemContainer {
          margin: 5px;
          padding: 24px;
          border: 1px solid #00000033;
          border-radius: 15px;
          transition: transform 250ms ease-in-out;
          cursor: pointer;

          @include mixins.respond(tablet) {
            padding: 36px;
          }

          @include mixins.respond(mobile) {
            padding: 34px;
          }

          &:hover {
            transform: translateY(-6px);
            // box-shadow: 0px 4px 10px #72A0C1;
            // box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
            // border: none;
            box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
            transition-delay: 0.5s;

            img {
              animation: pulse 1.5s 250ms;
              transform-origin: center center;

              @keyframes pulse {
                0% {
                  transform: scale(1);
                }
                25% {
                  transform: scale(0.95);
                }
                35% {
                  transform: scale(0.87);
                }
                45% {
                  transform: scale(1.15);
                }
                55% {
                  transform: scale(0.87);
                }
                65% {
                  transform: scale(1.15);
                }
                75% {
                  transform: scale(1.08);
                }
                100% {
                  transform: scale(1);
                }
              }
            }
          }

          img {
            height: 70px;
            width: auto;
            margin: 4px;
          }
          h2 {
            font-size: 23px;
            font-weight: 400;
            color: #0a2252;
            margin: 8px 0 0 0;
          }

          .serviceItemList {
            width: 80%;
            margin: 14px auto 20px auto;

            .serviceItemListItem {
              // display: flex;
              // align-items: flex-start;
              // justify-content: flex-start;
              // gap: 0 8px;
              color: #0f2137;
              margin: 7px 0;
              font-size: 16px;
              font-weight: 500;
              letter-spacing: 0.2px;
              line-height: 1.4;

              .serviceListIcon {
                // flex-basis: 24px;
                // flex-grow: 0;
                flex-shrink: 0;
                transform: rotate(90deg);
                font-size: 10px;
                color: #003399;
              }

              .serviceListIconImg {
                flex-shrink: 0;
                height: 11px;
                width: auto;
                margin-top: 2px !important;
              }

              p {
                font-size: 18px;
              }
            }

            // .serviceItemList::marker{
            //     padding-left: 20px;
            // }
          }

          .double {
            align-self: flex-start !important;
            align-items: center !important;
            justify-content: space-between !important;
          }

          .ButtonContainer {
            align-self: flex-end;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 0 12px;

            .filled-btn {
              padding: 10px 12px;
              background-color: #dd1134;
              border: 2px solid #dd1134;
              border-radius: 5px;
              font-size: 14px;
              color: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;

              // @include mixins.respond(mobile) {
              //     background-color: yellow;
              //   }
            }

            .outline-btn {
              box-sizing: border-box;
              -moz-box-sizing: border-box;
              -webkit-box-sizing: border-box;
              padding: 10px 12px;
              background-color: none;
              border: 2px solid #dd1134;
              border-radius: 5px;
              font-size: 14px;
              font-weight: 500;
              color: #dd1134;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
