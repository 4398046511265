@use 'src/constants/styles/mixins.scss';

.product-main-container{     
    // width: 75vw;
    // margin: 100px auto;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 82px;
    height: 100%;

    @include mixins.respond(lgdesktop) {
        gap: 200px;
    }


    @include mixins.respond(desktop) {
        gap: 164px;
    }

    @include mixins.respond(tablet) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 32px;
    }

    @include mixins.respond(mobile) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 32px;
    }

    .serviceItemContainer{
        padding: 24px;
        border: 1px solid #00000033;
        border-radius: 15px;
        transition: transform 250ms ease-in-out;
        cursor: pointer;

        @include mixins.respond(tablet) {
            padding: 36px;
        }

        @include mixins.respond(mobile) {
            padding: 34px;
        }
        
        &:hover{
            transform: translateY(-6px);
            // box-shadow: 0px 4px 10px #72A0C1;
            // box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
            // border: none;
            box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
            transition-delay: 0.5s;

            img {
                animation: pulse 1.5s 250ms;
                transform-origin: center center;

                @keyframes pulse {
                    0% {transform: scale(1);}
                    25% {transform: scale(.95);}
                    35% {transform: scale(.87);}
                    45% {transform: scale(1.15);}
                    55% {transform: scale(.87);}
                    65% {transform: scale(1.15);}
                    75% {transform: scale(1.08);}
                    100% {transform: scale(1);}
                }

            }
        }

        img {
            height: 70px;
            width: auto;
            margin: 4px;
        }
        h2{
            font-size: 1.5rem;
            font-weight: 400;
            color: #0A2252;
            margin: 8px 0 0 0;
        }

        .serviceItemList{
            width: 80%;
            margin: 14px auto 20px auto;

            .serviceItemListItem{
                // display: flex;
                // align-items: flex-start;
                // justify-content: flex-start;
                // gap: 0 8px;
                color: #0F2137;
                margin: 7px 0;
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.2px;
                line-height: 1.4;

                .serviceListIcon{
                    // flex-basis: 24px;
                    // flex-grow: 0;
                    flex-shrink: 0;
                    transform: rotate(90deg);
                    font-size: 10px;
                    color: #003399;
                }

                .serviceListIconImg{
                    flex-shrink: 0;
                    height: 11px;
                    width: auto;
                    margin-top: 2px!important;
                }

                p{
                    font-size: 18px;
                }
            }

            // .serviceItemList::marker{
            //     padding-left: 20px;
            // }
        }

        .double{
            align-self: flex-start!important;
            align-items: center!important;
            justify-content: space-between!important;
        }

        .ButtonContainer{
            align-self: flex-end;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 0 12px;

            .filled-btn{
                padding: 10px 12px;
                background-color: #DD1134;
                border: 2px solid #dd1134;
                border-radius: 5px;
                font-size: 14px;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                // @include mixins.respond(mobile) {
                //     background-color: yellow;
                //   }
            }

            .outline-btn{
                box-sizing: border-box;
                -moz-box-sizing: border-box;
                -webkit-box-sizing: border-box;
                padding: 10px 12px;
                background-color: none;
                border: 2px solid #dd1134;
                border-radius: 5px;
                font-size: 14px;
                font-weight: 500;
                color: #dd1134;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }
}