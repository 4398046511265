.loginDropCompContainer{
  padding: 0 12px;
  
  .item{
      a{
          display: flex;
          text-decoration: none;
          align-items: center;
          justify-content: flex-start;
          gap: 12px;
          padding: 12px 0;
          color: #0f2137;

          &:hover{
              color: #003399!important;
          }
      }

      p{
          font-size: 16px;
          font-weight: 500;
      }

      img{
          height: 20px;
      }

      .underline{
        width: 100%;
        height: 1px;
        background-color: #00000050;
    }
  }
}