@use 'src/constants/styles/mixins.scss';

.memberPageMainContainer{
    .tabs{
        .tabList{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            gap: 16px 30px;

            .button{
                padding: 7px 22px; 
                font-size: 17px;
                font-weight: 500;
                color: #0F2137;
                background-color: transparent;
                border: 2px solid #dd1134;
                border-radius: 25px;
                cursor: pointer;
                transition: transform 250ms ease-in-out; 

                &::marker{
                    content: '';
                }

                &:hover{
                    color: #fff;
                    background-color: #dd1134;
                    border: 2px solid #dd1134;
                    transform: translateY(-2px);
                }
            }
            
            .react-tabs__tab--selected{
                color: #fff;
                background-color: #dd1134;
                border-radius: 25px;

                &:focus-visible{
                    outline: none;
                }

                &::marker{
                    content: '';
                }
            }   
        }

        .resultsContainer{
            margin-top: 38px;    
            gap: 18px 24px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;

            @include mixins.respond(tablet) {
                grid-template-columns: 1fr 1fr;
            }

            @include mixins.respond(mobile) {
                grid-template-columns: 1fr;
            }

            .item{
                // display: flex;
                // justify-content: space-between;
                // align-items: center;
                /* gap: 0px 28px; */
                padding: 14px 20px;
                border: 2px solid #003399;
                border-radius: 10px;
                cursor: pointer;
                transition: transform 250ms ease-in-out;  


                &:hover{
                    transform: translateY(-4px);
                    box-shadow: 0px 4px 10px #00339950;
                }

                p{
                    color: #3a3a3a;
                    font-size: 18px;
                    font-weight: 500;
                    text-align: center;

                    @include mixins.respond(tablet) {
                        font-size: 15px;
                    }
                }
            }
            

            

        }

    }
}

// .react-tabs__tab--selected{
//     color: #fff;
//     background-color: #003399;
//     border-radius: 25px;

//     &:focus-visible{
//         outline: none;
//     }

//     &::marker{
//         content: '';
//     }
// }