@mixin respond($breakpoint) {
    
    @if($breakpoint == mobile)  {
        @media (max-width: 480px) { @content }
    }

    @if($breakpoint == tablet)  {
      @media (max-width: 800px) { @content }
    }

    @if($breakpoint == laptop)  {
      @media (max-width: 1500px) { @content }
    }

    @if($breakpoint == desktop)  {
        @media only screen and (min-width: 1501px) and (max-width: 1921px) { @content }
    }

    @if($breakpoint == lgdesktop)  {
      @media only screen and (min-width: 1922px) and (max-width: 2400px) { @content }
  }
} 