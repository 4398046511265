.notificationContainer{
    padding: 12px;

    .listContainer{
        // margin-bottom: 20px;

        .item{
            h2{
                font-size: 17px;
                padding: 8px 0;
                color: #3a3a3a;
                letter-spacing: 0.5px;

                &:hover{
                    cursor: pointer;
                    color: #003399;
                }
            }

            p{
                font-weight: 500;
                font-size: 14px;
                margin-bottom: 10px;
                color: #666;
            }

            .underline{
                width: 100%;
                height: 1px;
                background-color: #00000050;
            }
        }
    }

    .MoreFlexContainer{
        margin-top: 10px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        a {
            display: inline-block;
            position: relative;
            color: #dd1134;
            font-size: 15px;
            font-weight: 600;
            cursor: pointer;
          }
          
          a::after {
            content: '';
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: #dd1134;
            transform-origin: bottom right;
            transition: transform 0.25s ease-out;
          }
          
          a:hover::after {
            transform: scaleX(1);
            transform-origin: bottom left;
          }
    }
    
}