.searchPageMainContainer{
    form{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 32px;

        .dialogSearchInput{
            border-radius: 6px 0 0 6px;
            width: 450px;
            padding: 8.5px 14px;
            font-size: var(--font-sm);
            color: #0f2137;
            border: 2px solid #003399;
        }
        .dialogSearchInput:focus{
            border: 2px solid #003399;
        }

        .contactFormResetButton {
            border-radius: 0 6px 6px 0!important;
            border-style: none;
            box-sizing: border-box;
            color: #FFFFFF;
            cursor: pointer;
            display: inline-block;
            font-size: 15px;
            font-weight: 500;
            letter-spacing: 0.5px;
            height: 40px;
            line-height: 20px;
            list-style: none;
            margin: 0;
            outline: none;
            padding: 10px 26px;
            position: relative;
            text-align: center;
            text-decoration: none;
            transition: color 100ms;
            vertical-align: baseline;
            user-select: none;
            -webkit-user-select: none;
            touch-action: manipulation;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
          }

          .contactSubmitButton{
            background-color: rgba(0, 51, 153, 1);

            &:hover{
                background-color: rgba(0, 51, 153, 0.9);
            }
        }
        
    }
}