@use "src/constants/styles/mixins.scss";

.testimonialMainContainer {
  max-width: 95vw;
  margin: 34px auto;
  padding-bottom: 60px;

  @include mixins.respond(lgdesktop) {
    max-width: 75vw;
  }

  @include mixins.respond(desktop) {
    max-width: 75vw;
  }

  @include mixins.respond(tablet) {
    padding-bottom: 100px;
  }

  @include mixins.respond(mobile) {
    padding-bottom: 230px;
  }

  //   @include mixins.respond(mobile) {
  //     max-width: 95vw;
  //   }

  h1 {
    padding-bottom: 44px;
    font-family: "DM Sans", sans-serif;
    color: #0f2137;
    font-size: 46px;
    font-weight: bold;

    @include mixins.respond(tablet) {
      font-size: 32px;
    }
  }

  .testimonial-carousel {
    .sliderContainer {
      position: relative;
      width: 100%;
      margin: auto;

      .test-back {
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        background-color: transparent !important;
        border: none;
        border-radius: 50%;

        @include mixins.respond(tablet) {
          display: none;
        }

        .btn-left {
          color: #0f2137;
          height: 32px;
          width: 32px;

          &:hover {
            color: #b60b27;
          }
        }
      }

      .test-next {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        background-color: transparent !important;
        border: none;
        border-radius: 50%;

        @include mixins.respond(tablet) {
          display: none;
        }

        @include mixins.respond(desktop) {
          right: 30px;
        }

        // @include mixins.respond(lgdesktop) {
        //     right: 0px!important;
        // }

        .btn-right {
          color: #0f2137;
          height: 32px;
          width: 32px;

          &:hover {
            color: #b60b27;
          }
        }
      }

      .testimonialContainer {
        padding: 0 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0 120px;

        @include mixins.respond(tablet) {
          flex-direction: column;
          gap: 120px 0;
        }

        @include mixins.respond(mobile) {
          padding: 0 20px;
          gap: 60px 0;
        }

        .profile-img {
          height: 380px;
          width: 380px;
          object-fit: cover;
          border-radius: 8px;
        }

        .text-container {
          @include mixins.respond(desktop) {
            width: 60%;
          }

          .text-relative-container {
            position: relative;
            margin-bottom: 18px;

            .icon-start-abs {
              position: absolute;
              top: -42px;
              left: -32px;
              color: #dd1134;
              font-size: 52px;

              @include mixins.respond(mobile) {
                top: -12px;
                left: -12px;
                font-size: 24px;
              }
            }

            .icon-end-abs {
              position: absolute;
              bottom: -10px;
              right: 0;
              color: #dd1134;
              font-size: 52px;

              @include mixins.respond(mobile) {
                font-size: 24px;
              }
            }

            .testimonial-text {
              padding: 0 20px;
              font-size: 22px;
              font-weight: 500;
              color: #0f2137;
              letter-spacing: 0.5px;
              line-height: 150%;

              @include mixins.respond(mobile) {
                font-size: 20px;
              }
            }
          }

          h3 {
            padding: 0 20px;
            color: #003399;
            font-size: 22px;
            font-weight: 600;
            padding-bottom: 4px;
            padding-top: 12px;
          }
          h4 {
            padding: 0 20px;
            color: #3a3a3a;
          }
        }
      }
    }
  }
}
