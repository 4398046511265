@use 'src/constants/styles/mixins.scss';

.forexContainer{
    .forexPagesHeaderFlexContainer{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;

        @include mixins.respond(mobile) {
            flex-direction: column;
            gap: 24px;
        }

        h3{
            color: #dd1134;
        }
    }

    .flag-code-container{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        color: #36454f;
    }
}

