@use 'src/constants/styles/mixins.scss';

.calendarPageMainContainer{
    width: 70%;
    margin: 0 auto;

    @include mixins.respond(tablet) {
        width: 100%;
    }

    .calendar-carousel-provider{
        .btn-ctrl{
            margin-bottom: 24px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 26px;

            @include mixins.respond(mobile) {
                margin-bottom: 14px;
                gap: 20px;
            }

            .cus-back{
                background-color: #003399;
                border: none;
                border-radius: 12px;

                @include mixins.respond(mobile) {
                    border-radius: 8px;
                }

                &:hover{
                    background-color: #002244;
                    scale: 1.1;
                }

                &:active{
                    scale: 0.8;
                }

                .btn-left{
                    font-size: 30px;
                    color: #fff;
                    margin: 8px;

                    @include mixins.respond(mobile) {
                        font-size: 24px;
                    }
                }
            }

            .cus-next{
                background-color: #003399;
                border: none;
                border-radius: 12px;

                @include mixins.respond(mobile) {
                    border-radius: 8px;
                }

                &:hover{
                    background-color: #002244;
                    scale: 1.1;
                }

                &:active{
                    scale: 0.8;
                }

                .btn-right{
                    font-size: 30px;
                    color: #fff;
                    margin: 8px;

                    @include mixins.respond(mobile) {
                        font-size: 24px;
                    }
                }   
            }
        }
    }
}