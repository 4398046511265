@use "src/constants/styles/mixins.scss";

.newsSectionMainContainer {
  max-width: 80vw;
  margin: 32px auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 0 32px;

  @include mixins.respond(desktop) {
    max-width: 70vw;
    gap: 0 80px;
  }

  @include mixins.respond(lgdesktop) {
    max-width: 70vw;
    gap: 0 80px;
  }

  @include mixins.respond(tablet) {
    // flex-direction: column;
    max-width: 95vw;
    margin: 12px auto;
  }

  @include mixins.respond(mobile) {
    flex-direction: column-reverse;
    gap: 26px 0;
  }

  .facebook-page-plugin-container {
    width: 30%;

    @include mixins.respond(tablet) {
      display: none;
    }

    @include mixins.respond(mobile) {
      display: none;
    }
  }

  .news-carousel-provider {
    width: 40%;
    position: relative;
    top: 0;
    left: 0;

    @include mixins.respond(desktop) {
      width: 35%;
    }

    @include mixins.respond(lgdesktop) {
      width: 30%;
    }

    @include mixins.respond(tablet) {
      display: none;
    }

    @include mixins.respond(mobile) {
      // width: 80%;
      display: block;
      width: 100%;
    }

    .sliderContainer {
      // width: 400px;
      position: relative;
      top: 0;
      left: 0;

      .singleSlide {
        position: relative;
        top: 0;
        left: 0;
        .image {
          object-fit: cover;
          position: relative;
          top: 0;
          left: 0;
        }

        .image-overlay {
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.0001) 0%,
            #000000 100%
          );
          mix-blend-mode: normal;
          opacity: 0.9;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;

          .text {
            width: 100%;
            padding: 0 20px;
            color: #fff;
            position: absolute;
            bottom: 62px;
            left: 10px;

            p {
              font-family: "Roboto", sans-serif;
              font-size: 17px;
              font-weight: 300;
              margin-bottom: 12px;
              letter-spacing: 1px;
              line-height: 130%;
            }

            h3 {
              font-family: "DM Sans", sans-serif;
              font-size: 17px;
              font-weight: 500;
              margin: 4px 0;
            }

            .read-btn-container {
              max-width: 85%;
              margin: 0 auto;
              display: flex;
              align-items: center;
              justify-content: flex-end;

              .read-btn {
                border: 1.5px solid #fff;
                border-radius: 5px;
                padding: 4px;
                width: 32%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 13px;
                font-weight: 400;
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .next-back-btn-container {
      // z-index: 100;
      position: absolute;
      bottom: 12px;
      right: 28px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 22px;
      .cus-back {
        // height: 40px;
        // width: auto;
        background-color: transparent;
        // border-radius: 50%;
        border: none;

        svg {
          color: none;
          padding: 6px;
          border-radius: 50%;

          &:hover {
            background-color: rgba(0, 0, 0, 0.6);
            scale: 1.1;
          }

          &:active {
            scale: 0.8;
          }
        }
      }

      .cus-next {
        // height: 40px;
        // width: auto;
        background-color: transparent;
        // border-radius: 50%;
        border: none;

        svg {
          color: none;
          padding: 6px;
          border-radius: 50%;

          &:hover {
            background-color: rgba(0, 0, 0, 0.6);
            scale: 1.1;
          }

          &:active {
            scale: 0.8;
          }
        }
      }
    }
  }

  .contentMainContainer {
    width: 50%;

    @include mixins.respond(tablet) {
      width: 100%;
    }

    @include mixins.respond(mobile) {
      width: 100%;
    }

    h2 {
      font-family: "DM Sans", sans-serif;
      font-weight: bold;
      font-size: 46px;
      color: #0f2137;
      margin-bottom: 16px;

      @include mixins.respond(tablet) {
        font-size: 32px;
      }
    }

    .newsGrid {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      gap: 24px 0;

      @include mixins.respond(tablet) {
        flex-wrap: wrap;
        flex-direction: row;
        // gap: 0 10px;
      }

      @include mixins.respond(mobile) {
        flex-wrap: nowrap;
        flex-direction: column;
      }

      .newsItem {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0 24px;
        cursor: pointer;

        @include mixins.respond(tablet) {
          width: 45%;
        }

        @include mixins.respond(mobile) {
          width: 100%;
        }

        &:hover {
          padding: 0 1px;
          border-radius: 10px;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
            rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        }

        img {
          width: 100px;
          height: 100px;
          object-fit: contain;
          border-radius: 10px;
        }

        .titleTextContainer {
          h3 {
            width: 90%;

            @include mixins.respond(tablet) {
              width: 100%;
            }
          }
        }
      }
    }

    .read-all-button {
      margin-top: 18px;
      max-width: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #dd1134;
      border: 2px solid #dd1134;
      border-radius: 5px;
      padding: 6px;
      font-weight: 500;
      font-size: 16px;
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      box-shadow: 0 0 1px rgba(0, 0, 0, 0);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -moz-osx-font-smoothing: grayscale;
      position: relative;
      -webkit-transition-property: color;
      transition-property: color;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;

      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #dd1134;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 0 50%;
        transform-origin: 0 50%;
        -webkit-transition-property: transform;
        transition-property: transform;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
      }

      &:hover,
      &:focus,
      &:active {
        color: #fff;
        // font-weight: 400;
        // font-size: 15px;
      }

      &:hover:before,
      &:focus:before,
      &:active:before {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        //  font-weight: 400;
        //  font-size: 15px;
      }
    }
  }
}
