@use 'src/constants/styles/mixins.scss';

.csrMessageSectionMainBgWrapperMain{
    .ceoMessageSectionMainBgWrapper{
        position: relative;
        overflow: hidden;
    
        &:after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            // background-color: rgb(144, 160, 255);
            background-image: url('../../assets/icfcBg.jpg');
            background-repeat: no-repeat;
            // background-position: center;
            background-origin: padding-box;
            // border-radius: 50%;
            // top: -10%;
            // right: -5%;
            z-index: -1;
        }
    

    
    }
}


.csrSectionContainer{
    max-width:85vw;
    margin: -40px auto 0 auto;
    // padding: 0 0 42px 0;
    padding-bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // position: relative;
    overflow: hidden;   

    @include mixins.respond(tablet) {
        flex-direction: column;
    }

    &:after{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        // background-color: rgb(144, 160, 255);
        background-image: url('../../assets/bg04.png');
        background-repeat: repeat;
        background-size: auto;
        opacity: 0;
        // background-position: center;
        // background-origin: padding-box;
        // border-radius: 50%;
        // top: -10%;
        // right: -5%;
        left: 0;
        right: 0;
        z-index: -1;
    }

    .contentContainer{
        width: 50%;
        padding-left: 32px;
        padding-bottom: 68px;

        @include mixins.respond(tablet) {
            width: 100%;
            padding: 32px 0;
            // padding-bottom: 68px;
        }

        h2{
            font-family: "Amithen";
            font-weight: normal;
            font-size: 68px;
            color: #DD1134;
            padding-bottom: 16px;

            @include mixins.respond(tablet) {
                font-size: 52px;
                padding-bottom: 8px;
            }
        }

        .black-text{
            color: #0F2137;
            font-size: 40px;
            font-weight: 500;
            margin-bottom: -4px;

            @include mixins.respond(tablet) {
                // font-size: 30px;
            }

            @include mixins.respond(mobile) {
                font-size: 30px;
            }
        }
        .blue-text{
            color: #003399;
            font-size: 44px;
            font-weight: 600;

            @include mixins.respond(mobile) {
                font-size: 34px;
            }
        }

        .button{
            margin-top: 34px;
            max-width: 200px;
            padding: 10px 16px;
            border-radius: 8px;
            border: 2px solid #DD1134;
            text-align: center;
            color: #DD1134;
            font-size: 16px;
            font-weight: 600;
            cursor: pointer;
            display: inline-block;
            vertical-align: middle;
            -webkit-transform: translateZ(0);
            transform: translateZ(0);
            box-shadow: 0 0 1px rgba(0, 0, 0, 0);
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
            -moz-osx-font-smoothing: grayscale;
            position: relative;
            -webkit-transition-property: color;
            transition-property: color;
            -webkit-transition-duration: 0.3s;
            transition-duration: 0.3s;

            @include mixins.respond(tablet) {
                margin-top: 12px;
            }

            &:before{
                content: "";
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: #DD1134;
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transform-origin: 0 50%;
                transform-origin: 0 50%;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-timing-function: ease-out;
                transition-timing-function: ease-out;
            }

            &:hover, &:focus, &:active{
                color: #fff;
            }

            &:hover:before, &:focus:before, &:active:before{
                  -webkit-transform: scaleX(1);
                 transform: scaleX(1);

            }
        }
    }

    .rightFlexContainer{
        // display: flex;
        // align-items: center;
        // justify-content: right;
        // gap: 0 18px;
        width: 45%;

        @include mixins.respond(mobile) {
            // justify-content: center;
            // gap: 0;
            width: 100%;
        }

        img{
            height: auto;
            width: 100%;

            @include mixins.respond(mobile) {
                width: 100%;
            }
        }
        // .twoFlexContainer{
        //     @include mixins.respond(mobile) {
        //         display: none;
        //     }
        // }
    }

}



