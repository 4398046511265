$blue-color: #003399;
$red-color: #dd1134;
$dark-text: #0f2137;
$light-text: #fff;

$font-xl: 62px;
// $font-lg: ;
// $font-md: ;
// $font-sm: ;
$font-dm: DM Sans, sans-serif;
$font-cursive: Amithen;