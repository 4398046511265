.HomePageModalImage{
    width: 100%;
  }

  /* .HomePageModalImage */
.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop{
    /* background-color: rgba(0, 0, 0, 0.1)!important; */
    background-color: transparent!important;
  }

  .css-919eu4 {
    background-color: transparent!important;
  }
  
  /* .HomePageModalContentBox{
    padding: 100px!important;
  } */
  
  .css-153eyq5-MuiModal-root-MuiDialog-root .MuiDialogContent-root{
    padding: 0px!important;
  }

  .css-74waha .MuiDialogContent-root{
    padding: 0px!important;
  }
  
  .HomepageModalKnowButtonContainer{
    /* background-color: #009E60; */
    /* padding: 8px 16px; */
    background-color: rgba(60, 60, 60, 0.8);
    padding: 5px 8px;
    text-decoration: none;
    /* margin: 0 12px; */
    /* border-radius: 8px; */
    border-radius: 15%;
  }
  
  .HomepageModalKnowButtonContainer:hover{
    background-color: rgb(60, 60, 60);
  }
  
  .HomepageModalKnowButtonContainer > p{
    color: #fff;
    font-weight: 300;
    font-size: 15px;
  }
  
  .HomepageModalCloseButtonContainer {
    background-color: #991b1b;
    padding: 5px 16px;
    text-decoration: none;
    font-weight: 300;
    /* margin: 0 6px; */
    border-radius: 8px;
    max-width: 100px;
  }
  .HomepageModalCloseButtonContainer:hover {
    background-color: #b91c1c;
  }
  
  
  .HomepageModalCloseButtonContainer > p {
    color: #fff;
    font-weight: 300;
    font-size: 15px;
  }
  
  
  /* --------- */
  /*Scrollbar   */
  /* --------- */
  
  #HomepageModalCustomScroll::-webkit-scrollbar {
      width: 6px!important;
    }
    
    /* Track */
  #HomepageModalCustomScroll::-webkit-scrollbar-track {
      background: #c2c2c2!important;
    }
    
    /* Handle */
  #HomepageModalCustomScroll::-webkit-scrollbar-thumb {
      background: #003399!important;
    }
    
    /* Handle on hover */
  #HomepageModalCustomScroll::-webkit-scrollbar-thumb:hover {
      background: #003399!important;
    }
  
    .homepageModalTitleContainer{
      display: flex!important;
      justify-content: space-between!important;
      align-items: center!important;
    }
  
  