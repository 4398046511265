.audio-player{
  padding: 18px;
    .inner {

      .audio-info{
        .text {
          h3{
            color: #0f2137;
            font-size: 20px;
            padding-bottom: 8px;
          }

          p{
            color: #3b3c36;
            font-size: 16px;
          }
        }
      }

        .progress{
          padding-top: 30px;

          input[type="range"] {
            font-size: 1.5rem;
            width: 100%;
            color: #282C35;
            --thumb-height: 0.7em;
            --track-height: 0.125em;
            --track-color: rgba(0, 0, 0, 0.2);
            --brightness-hover: 180%;
            --brightness-down: 80%;
            --clip-edges: 0.125em;
          }
          input[type="range"].win10-thumb {
            color: #2b2d42;
            --thumb-height: 1.375em;
            --thumb-width: 0.5em;
            --clip-edges: 0.0125em;
            color: #3a86ff;
          }
          // @media {
          //   (prefers-color-scheme {
          //     &: {
          //       dark) {
          //         input[type="range"] {
          //     color: #f07167;
          //         --track-color: rgba(255, 255, 255, 0.1);
          //       }
          //     }
          //   }
          // }
          // }

          // {
            input[type="range"] {
              position: relative;
              background: #fff0;
              overflow: hidden;
            }
          // }

          input[type="range"]:active {
            cursor: grabbing;
            &::-webkit-slider-thumb {
              filter: brightness(var(--brightness-down));
              cursor: grabbing;
            }
            &::-moz-range-thumb {
              cursor: grabbing;
            }
          }
          input[type="range"]:disabled {
            filter: grayscale(1);
            opacity: 0.3;
            cursor: not-allowed;
            &::-webkit-slider-thumb {
              cursor: not-allowed;
            }
            &::-moz-range-thumb {
              cursor: not-allowed;
            }
          }
          input[type="range"],
          input[type="range"]::-webkit-slider-runnable-track,
          input[type="range"]::-webkit-slider-thumb {
            -webkit-appearance: none;
            transition: all ease 100ms;
            height: var(--thumb-height);
          }
          input[type="range"]::-webkit-slider-runnable-track,
          input[type="range"]::-webkit-slider-thumb {
            position: relative;
          }
          input[type="range"]::-webkit-slider-thumb {
            --thumb-radius: calc((var(--thumb-height) * 0.5) - 1px);
            --clip-top: calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px);
            --clip-bottom: calc(var(--thumb-height) - var(--clip-top));
            --clip-further: calc(100% + 1px);
            --box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0
           100vmax currentColor;
            width: var(--thumb-width, var(--thumb-height));
            background: linear-gradient(currentColor 0 0) scroll no-repeat left center /
           50% calc(var(--track-height) + 1px);
            background-color: currentColor;
            box-shadow: var(--box-fill);
            border-radius: var(--thumb-width, var(--thumb-height));
            filter: brightness(100%);
            clip-path: polygon(
           100% -1px,
              var(--clip-edges) -1px,
              0 var(--clip-top),
              -100vmax var(--clip-top),
              -100vmax var(--clip-bottom),
              0 var(--clip-bottom),
              var(--clip-edges) 100%,
              var(--clip-further) var(--clip-further)
            );
          }
          input[type="range"]:hover {
            &::-webkit-slider-thumb {
              filter: brightness(var(--brightness-hover));
              cursor: grab;
            }
          }
          input[type="range"]::-webkit-slider-runnable-track {
            background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center /
           100% calc(var(--track-height) + 1px);
          }
          input[type="range"],
          input[type="range"]::-moz-range-track,
          input[type="range"]::-moz-range-thumb {
            appearance: none;
            transition: all ease 100ms;
            height: var(--thumb-height);
          }
          input[type="range"]::-moz-range-track,
          input[type="range"]::-moz-range-thumb,
          input[type="range"]::-moz-range-progress {
            background: #fff0;
          }
          input[type="range"]::-moz-range-thumb {
            background: currentColor;
            border: 0;
            width: var(--thumb-width, var(--thumb-height));
            border-radius: var(--thumb-width, var(--thumb-height));
            cursor: grab;
          }
          input[type="range"]::-moz-range-track {
            width: 100%;
            background: var(--track-color);
          }
          input[type="range"]::-moz-range-progress {
            appearance: none;
            background: currentColor;
            transition-delay: 30ms;
          }
          input[type="range"]::-moz-range-track,
          input[type="range"]::-moz-range-progress {
            height: calc(var(--track-height) + 1px);
            border-radius: var(--track-height);
          }
          input[type="range"]::-moz-range-thumb,
          input[type="range"]::-moz-range-progress {
            filter: brightness(100%);
          }
          input[type="range"]:hover::-moz-range-thumb,
          input[type="range"]:hover::-moz-range-progress {
            filter: brightness(var(--brightness-hover));
          }
          input[type="range"]:active::-moz-range-thumb,
          input[type="range"]:active::-moz-range-progress {
            filter: brightness(var(--brightness-down));
          }

            .AudioTimes{
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #3b3c36;
                font-size: 14px;
            }
        }

        .controls-wrapper{
          .controls{
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .volume{
            padding: 12px 16px 0 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          input[type="range"] {
            font-size: 1.5rem;
            width: 100%;
            color: #282C35;
            --thumb-height: 0.7em;
            --track-height: 0.125em;
            --track-color: rgba(0, 0, 0, 0.2);
            --brightness-hover: 180%;
            --brightness-down: 80%;
            --clip-edges: 0.125em;
          }
          input[type="range"].win10-thumb {
            color: #2b2d42;
            --thumb-height: 1.375em;
            --thumb-width: 0.5em;
            --clip-edges: 0.0125em;
            color: #3a86ff;
          }
          // @media {
          //   (prefers-color-scheme {
          //     &: {
          //       dark) {
          //         input[type="range"] {
          //     color: #f07167;
          //         --track-color: rgba(255, 255, 255, 0.1);
          //       }
          //     }
          //   }
          // }
          // }

          // {
            input[type="range"] {
              position: relative;
              background: #fff0;
              overflow: hidden;
            }
          // }

          input[type="range"]:active {
            cursor: grabbing;
            &::-webkit-slider-thumb {
              filter: brightness(var(--brightness-down));
              cursor: grabbing;
            }
            &::-moz-range-thumb {
              cursor: grabbing;
            }
          }
          input[type="range"]:disabled {
            filter: grayscale(1);
            opacity: 0.3;
            cursor: not-allowed;
            &::-webkit-slider-thumb {
              cursor: not-allowed;
            }
            &::-moz-range-thumb {
              cursor: not-allowed;
            }
          }
          input[type="range"],
          input[type="range"]::-webkit-slider-runnable-track,
          input[type="range"]::-webkit-slider-thumb {
            -webkit-appearance: none;
            transition: all ease 100ms;
            height: var(--thumb-height);
          }
          input[type="range"]::-webkit-slider-runnable-track,
          input[type="range"]::-webkit-slider-thumb {
            position: relative;
          }
          input[type="range"]::-webkit-slider-thumb {
            --thumb-radius: calc((var(--thumb-height) * 0.5) - 1px);
            --clip-top: calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px);
            --clip-bottom: calc(var(--thumb-height) - var(--clip-top));
            --clip-further: calc(100% + 1px);
            --box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0
           100vmax currentColor;
            width: var(--thumb-width, var(--thumb-height));
            background: linear-gradient(currentColor 0 0) scroll no-repeat left center /
           50% calc(var(--track-height) + 1px);
            background-color: currentColor;
            box-shadow: var(--box-fill);
            border-radius: var(--thumb-width, var(--thumb-height));
            filter: brightness(100%);
            clip-path: polygon(
           100% -1px,
              var(--clip-edges) -1px,
              0 var(--clip-top),
              -100vmax var(--clip-top),
              -100vmax var(--clip-bottom),
              0 var(--clip-bottom),
              var(--clip-edges) 100%,
              var(--clip-further) var(--clip-further)
            );
          }
          input[type="range"]:hover {
            &::-webkit-slider-thumb {
              filter: brightness(var(--brightness-hover));
              cursor: grab;
            }
          }
          input[type="range"]::-webkit-slider-runnable-track {
            background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center /
           100% calc(var(--track-height) + 1px);
          }
          input[type="range"],
          input[type="range"]::-moz-range-track,
          input[type="range"]::-moz-range-thumb {
            appearance: none;
            transition: all ease 100ms;
            height: var(--thumb-height);
          }
          input[type="range"]::-moz-range-track,
          input[type="range"]::-moz-range-thumb,
          input[type="range"]::-moz-range-progress {
            background: #fff0;
          }
          input[type="range"]::-moz-range-thumb {
            background: currentColor;
            border: 0;
            width: var(--thumb-width, var(--thumb-height));
            border-radius: var(--thumb-width, var(--thumb-height));
            cursor: grab;
          }
          input[type="range"]::-moz-range-track {
            width: 100%;
            background: var(--track-color);
          }
          input[type="range"]::-moz-range-progress {
            appearance: none;
            background: currentColor;
            transition-delay: 30ms;
          }
          input[type="range"]::-moz-range-track,
          input[type="range"]::-moz-range-progress {
            height: calc(var(--track-height) + 1px);
            border-radius: var(--track-height);
          }
          input[type="range"]::-moz-range-thumb,
          input[type="range"]::-moz-range-progress {
            filter: brightness(100%);
          }
          input[type="range"]:hover::-moz-range-thumb,
          input[type="range"]:hover::-moz-range-progress {
            filter: brightness(var(--brightness-hover));
          }
          input[type="range"]:active::-moz-range-thumb,
          input[type="range"]:active::-moz-range-progress {
            filter: brightness(var(--brightness-down));
          }
          


            .AudioTimes{
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }
    }
}

