@use "src/constants/styles/mixins.scss";

.downloadsPageMainContainer {
  max-width: 80vw;
  margin: 0 auto;

  .downloadsPageTabContainer {
    .downloadsPageTabsButtonContainer {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      // gap: 16px 30px;

      .downloadsPageTabButton {
        padding: 7px 22px;
        font-size: 18px;
        font-weight: 500;
        color: #0f2137;
        background-color: transparent;
        border: 2px solid #dd1134;
        border-radius: 25px;
        cursor: pointer;
        transition: transform 250ms ease-in-out;
        margin-bottom: 20px;

        @include mixins.respond(tablet) {
          font-size: 15px;
        }

        &:hover {
          color: #fff;
          background-color: #dd1134;
          border: 2px solid #dd1134;
          transform: translateY(-2px);
        }

        &::marker {
          content: "";
        }
      }

      .react-tabs__tab--selected {
        color: #fff;
        background-color: #dd1134;
        border-radius: 25px;

        &:focus-visible {
          outline: none;
        }

        &::marker {
          content: "";
        }
      }
    }

    .downloadsPageResultContainer {
      margin-top: 28px;
      gap: 18px 24px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      @include mixins.respond(tablet) {
        grid-template-columns: 1fr 1fr;
      }

      @include mixins.respond(mobile) {
        grid-template-columns: 1fr;
      }

      .downloadsPageResultItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        /* gap: 0px 28px; */
        padding: 14px 20px;
        border: 2px solid #003399;
        border-radius: 10px;
        cursor: pointer;
        transition: transform 250ms ease-in-out;

        &:hover {
          transform: translateY(-4px);
          box-shadow: 0px 4px 10px #00339950;
        }

        .downloadsPageResultText {
          color: #3a3a3a;
          font-size: 18px;
          font-weight: 500;

          @include mixins.respond(tablet) {
            font-size: 15px;
          }
        }

        .icon {
          width: 15%;
        }
      }
    }
  }
}
