@use "src/constants/styles/mixins.scss";

.mobileBankRelativeContainer {
  position: relative;

  .mobileBankComponentContainer {
    height: auto;
    width: 100%;
    padding-top: 80px;
    color: #fff;
    overflow: hidden;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;

    @include mixins.respond(tablet) {
      padding-top: 180px;
    }

    @include mixins.respond(mobile) {
      padding-top: 230px;
    }

    .content-container {
      max-width: 75vw;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0 38px;

      @include mixins.respond(tablet) {
        max-width: 90vw;
        flex-direction: column-reverse;
        gap: 24px 0;
      }

      img {
        width: 38%;
        height: auto;

        @include mixins.respond(tablet) {
          width: 70%;
        }

        @include mixins.respond(mobile) {
          width: 90vw;
        }
      }

      .text-container {
        width: 60%;

        @include mixins.respond(tablet) {
          width: 80%;
        }

        @include mixins.respond(mobile) {
          width: 90vw;
        }

        h2 {
          font-family: "DM Sans", sans-serif;
          font-size: 36px;
          font-weight: 700;
          letter-spacing: 1px;
          line-height: 125%;
          margin-bottom: 24px;

          @include mixins.respond(mobile) {
            font-size: 24px;
            margin-bottom: 12px;
          }
        }

        p {
          width: 80%;
          font-family: "Roboto", sans-serif;
          font-size: 18px;
          font-weight: 300;
          letter-spacing: 1px;
          line-height: 150%;
          margin-bottom: 28px;

          @include mixins.respond(mobile) {
            width: 100%;
            margin-bottom: 12px;
          }
        }

        h3 {
          font-family: "DM Sans", sans-serif;
          font-size: 22px;
          font-weight: 400;
          // letter-spacing: 0.5px;
          // line-height: 150%;
          margin-bottom: 16px;

          @include mixins.respond(mobile) {
            font-size: 18px;
          }

          span {
            font-weight: 700;
            font-size: 24px;

            @include mixins.respond(mobile) {
              font-size: 20px;
            }
          }
        }
        .app-logo-container {
          display: flex;
          align-items: center;
          justify-content: left;
          gap: 0 38px;

          @include mixins.respond(mobile) {
            // flex-direction: column;
            // gap: 24px 0;
            gap: 0 24px;
          }

          img {
            height: 52px;
            width: auto;
            border-radius: 10px;

            @include mixins.respond(mobile) {
              justify-content: center;
              height: 44px;
            }
          }
        }
      }
    }
  }
}

.mobile-bank-page-title {
  font-family: "DM Sans", sans-serif;
  font-size: 22px;
  font-weight: 400;
  // letter-spacing: 0.5px;
  // line-height: 150%;
  margin: 24px 0;
  color: #0f2137;

  @include mixins.respond(mobile) {
    font-size: 18px;
  }

  span {
    font-weight: 700;
    font-size: 24px;

    @include mixins.respond(mobile) {
      font-size: 20px;
    }
  }
}

.app-logo-container {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 0 38px;

  @include mixins.respond(mobile) {
    // flex-direction: column;
    // gap: 24px 0;
    gap: 0 24px;
  }

  img {
    height: 52px;
    width: auto;
    border-radius: 10px;

    @include mixins.respond(mobile) {
      justify-content: center;
      height: 44px;
    }
  }
}
