@import "src/constants/styles/variables.scss";

.grievanceFormMainContainer {
  max-width: 90vw;
  margin: 0 auto;
  background-color: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .grievanceFormImage {
    max-width: 40%;
  }

  .grievancePageFormContainer {
    padding: 20px 42px;
    background-color: #fff;
    width: 50%;

    h3 {
      text-align: center;
      margin-bottom: 16px;
      font-size: 32px;
      font-weight: 400;
      color: #0f2137;
    }
    .grievanceFormInputContainer {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      label {
        color: #3a3a3a;
        font-size: var(--font-sm);
        font-weight: 500;

        margin-bottom: 6px;
      }

      input {
        padding: 7px 14px;
        font-size: var(--font-sm);
        color: #0f2137;
        &:focus {
          border: 2px solid #dc3545;
        }
      }

      textarea {
        padding: 7px 14px;
        font-size: var(--font-sm);
        color: #0f2137;
        &:focus {
          border: 2px solid #dc3545;
        }
      }

      span {
        color: #dc3545;
        margin-top: 2px;
      }
    }
  }
}

.grievanceFormButtonsContainer {
  margin: 22px 0;
  display: flex;
  gap: 22px;

  .grievanceFormResetButton {
    background-color: red;
  }
  .grievanceSubmitButton {
    background-color: rgba(221, 44, 26, 0.9);

    &:hover {
      background-color: rgba(221, 44, 26, 0.6);
    }
  }
  .grievanceCheckButton {
    background-color: rgba(33, 162, 96, 1);

    &:hover {
      background-color: rgba(33, 162, 96, 0.9);
    }
  }
}

.grievanceFormResetButton {
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  height: 40px;
  line-height: 20px;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 100ms;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.officers-profile {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  .information-profile {
    width: 49%;
    display: flex;
    padding: 20px;
    // justify-content: space-between;
    border-radius: 5px;
    box-shadow: 0px 0px 20px 1px #ddd;
    .i-img {
      width: 30%;
      border-radius: 5px;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .i-details {
      width: 70%;
      padding-left: 20px;
      h2 {
        text-transform: uppercase;
        margin-bottom: 5px;
        font-size: 20px;
        color: #3a3a3a;
      }
      h3 {
        margin-bottom: 5px;
        font-size: 18px;
        color: #3a3a3a;
      }
      p {
        margin-bottom: 5px;
        color: #555;
      }
    }
  }
  .grievance-profile {
    width: 49%;
    display: flex;
    padding: 20px;
    // justify-content: space-between;
    box-shadow: 0px 0px 20px 1px #ddd;
    border-radius: 5px;
    .i-img {
      width: 30%;
      border-radius: 5px;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .i-details {
      width: 70%;
      padding-left: 20px;
      h2 {
        text-transform: uppercase;
        margin-bottom: 5px;
        font-size: 20px;
        color: #3a3a3a;
      }
      h3 {
        margin-bottom: 5px;
        font-size: 18px;
        color: #3a3a3a;
      }
      p {
        margin-bottom: 5px;
        color: #555;
        a {
          color: inherit;
        }
      }
    }
  }
}

/* mobile devices */
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .grievanceFormMainContainer {
    flex-direction: column;
  }

  .grievanceFormImage {
    display: none;
  }

  .grievancePageFormContainer {
    padding: 15px;
    width: 100%;
  }

  .grievancePageFormContainer > h3 {
    font-size: 22px;
    font-weight: 500;
  }

  .grievanceFormButtonsContainer {
    margin: 28px 0;
    flex-direction: column;
    gap: 18px;
  }
}
