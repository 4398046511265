@import "src/constants/styles/variables.scss";

.faqPageMainContainer {
  max-width: 80vw;
  margin: 0 auto;

  .faqmain-tab {
    .faqmainbox {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 16px 30px;
      margin-bottom: 50px;
      .faqbtns {
        padding: 7px 22px;
        font-size: var(--font-sm);
        font-weight: 500;
        color: #0f2137;
        background-color: transparent;
        border: 2px solid #dd1134;
        border-radius: 25px;
        cursor: pointer;
        transition: transform 250ms ease-in-out;
        list-style: none;

        &:hover {
          color: #fff;
          background-color: #dd1134;
          border: 2px solid #dd1134;
          transform: translateY(-2px);
        }
      }
      .react-tabs__tab--selected {
        color: #fff;
        background-color: #dd1134;
        border-radius: 25px;
      }
      .react-tabs__tab--selected:focus-visible {
        outline: none;
      }

      .faqbtns::marker {
        content: "";
      }

      .react-tabs__tab--selected::marker {
        content: "";
      }
    }
  }

  .faqPageAccordianTitleText {
    font-family: $font-dm !important;
    color: $blue-color !important;
    font-size: 20px !important;
    font-weight: 500 !important;
  }

  .faqPageAccordianAnsText {
    font-family: $font-dm !important;
    color: $dark-text !important;
    font-size: 18px !important;
    font-weight: 400 !important;
  }
}
