@use 'src/constants/styles/mixins.scss';

.emiCalculatorMainContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0 24px;

    @include mixins.respond(desktop) {
        align-items: flex-start;
    }

    @include mixins.respond(tablet) {
        flex-wrap: wrap;
        // flex-direction: column;
        gap: 20px 0;
    }
}

.emiCalculatorInputMainContainer{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 24px 0;
    padding: 38px 22px;
    border: 1px solid #DEE2E6;
    border-radius: 10px;
    background-color: #fff;

    @include mixins.respond(tablet) {
        width: 100%;
        // gap: 12px 0;
        // padding: 28px 12px;
    }
}

.emiCalculatorInputFlexItem{
    display: flex;
    flex-direction: column;
}

.emiCalculatorAboveSlider{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.emiCalculatorSliderTitleContainer .emiCalculatorSliderTitleText{
    font-size: 20px;
    color: #0f2137;
}

.emiCalculatorSliderTitleContainer .emiCalculatorSliderTitleSubText{
    font-size: 17px;
    color: #3a3a3a;
    margin-bottom: 4px;
}

.emiCalculatorAmountInputContainer{
    display: flex;
}

.emiCalculatorAmountInput{
    border: 1px solid #CED4DA;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    padding: 5px 10px;
    outline: none;
    font-size: 17px;
    font-weight: 500;
    color: #495057;
}

.emiCalculatorAmountInput:focus{
    border: 1px solid #003399
}

.emiCalculatorAmountInputButton{
    background-color: #003399;
    width: 38px;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 18px;
}

.emiCalculatorResultContainer{
    width: 20%;
    padding: 38px 22px;
    border: 1px solid #DEE2E6;
    background-color: #fff;
    align-self: flex-start;
    /* background-color: #F3F6F9; */

    @include mixins.respond(tablet) {
        width: 50%;
    }
}

.emiCalculatorResultTitle{
    text-align: left;
    font-size: 24px;
    font-weight: 500;
    color: #0f2137;
    margin-bottom: 2px;
}

.emiCalculatorResultMonthlyEMI{
    text-align: left;
    font-size: 24px;
    font-weight: 600;
    color: #003399;
    margin-bottom: 22px;
}

.emiCalculatorResultRowContainer{
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    margin-bottom: 12px;
}

.emiCalculatorChartContainer{
    width: 30%;

    @include mixins.respond(tablet) {
        width: 50%;
    }

    canvas{
        width: 100%!important;
        height: 100%!important;
    }
}

.emiCalculatorResultRowText{
    font-size: 18px;
    font-weight: 500;
    color: #0f2137;
}

.emiCalculatorResultRowAns{
    font-size: 18px;
    font-weight: 600;
    color: #003399;
}

/* mobile devices */
@media only screen and (min-width: 320px) and (max-width: 480px) {
    .emiCalculatorMainContainer{
        flex-direction: column;
        gap: 20px 0;
    }

    .emiCalculatorResultContainer{
        width: 100%;
        padding: 20px 10px;
    }

    .emiCalculatorResultRowContainer{
        margin-bottom: 12px;
    }

    .emiCalculatorInputMainContainer{
        width: 100%;
        gap: 12px 0;
        padding: 28px 12px;
    }

    .emiCalculatorChartContainer{
    width: 100%;
    }

    .emiCalculatorAboveSlider{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .emiCalculatorAmountInput{
        width: 55%;
        padding: 4px 8px;
    }

    .emiCalculatorAmountInputButton{
        width: 30px;
        height: 30px;
        font-size: 16px;
    }
}