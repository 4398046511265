@use "src/constants/styles/mixins.scss";

// .mainNavContainer{
//     position: sticky;
//     top: 0;
//     z-index: 999;
//     width: 100%;

// @include mixins.respond(tablet) {
//     display: none;
// }

// .upNavDisplay{
//     display: none!important;
// }

.upNavBg {
  z-index: 800;
  width: 100%;
  // height: auto;
  padding: 2px 0;
  background-color: #003399;

  @include mixins.respond(tablet) {
    display: none;
  }
  .upNavContainer {
    max-width: 90vw;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mixins.respond(laptop) {
      max-width: 90vw;
    }

    @include mixins.respond(desktop) {
      max-width: 80vw;
    }

    @include mixins.respond(lgdesktop) {
      max-width: 80vw;
    }

    .upNavTextGroup {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 50px;
      color: #fff;

      p {
        cursor: pointer;
      }
    }

    .upNavBankingHour {
      // color: green;
      // font-weight: 500;
      display: flex;
      align-items: center;

      p {
        color: #fff;
        padding-left: 6px;
        font-size: 15px;
      }
    }

    .upNavDateGroup {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      // gap: 0 10px;
      color: #fff;

      .icon {
        margin-right: 5px;
      }
      p {
        font-size: 15px;
      }

      .pr {
        margin-right: 10px;
      }
    }

    .upNavExtraContainer {
      display: flex;
      align-items: center;

      .css-qiwgdb {
        padding: 8px 32px !important;
      }

      .css-1yhq19e {
        color: #fff !important;
      }

      .dateDropContainer {
        width: 180px;
        height: auto;
        position: absolute;
        top: 40px;
        right: 27%;
        z-index: 999;
        padding: 4px;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        border-radius: 8px;
        border-top: 1px solid #d3d3d3;
        padding: 8px 20px;

        h3 {
          color: #0f2137;
          margin-bottom: 5px;
        }

        p {
          color: #333;
          font-size: 15px;
          font-weight: 500;
          letter-spacing: 0.5px;
          margin-bottom: 3px;
        }
      }

      .audioDropContainer {
        width: 350px;
        height: auto;
        position: absolute;
        top: 40px;
        right: 8%;
        z-index: 900;
        padding: 4px;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        border-radius: 8px;
        border-top: 1px solid #d3d3d3;

        @include mixins.respond(desktop) {
          right: 12%;
        }
      }

      .noticeDropContainer {
        width: 280px;
        height: auto;
        position: absolute;
        top: 40px;
        right: 7%;
        z-index: 900;
        padding: 4px;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        border-radius: 8px;
        border-top: 1px solid #d3d3d3;

        @include mixins.respond(desktop) {
          right: 11%;
        }
      }

      // start
      .upIconContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0 26px;

        div {
          width: 38px;
          height: 38px;
          background-color: #f2f2f2;
          border-radius: 50%;
          // padding: 6px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            height: 22px;
            width: auto;
          }
        }
      }

      .upLangContainer {
        margin: 0 22px;
      }

      .upNavBtnGroup {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0 10px;

        .upNavBtnLogin {
          display: flex;
          align-items: center;
          gap: 0 6px;
          background-color: #f2f2f2;
          // padding: 6px 24px;
          padding: 8px 18px;
          border-radius: 8px;
          height: 34px;

          &:hover {
            cursor: pointer;
          }

          img {
            width: auto;
            height: 22px;
          }

          h3 {
            color: #0a2252;
            font-size: 16px;
            font-weight: 500;
          }
        }

        .loginDropContainer {
          width: 200px;
          height: auto;
          position: absolute;
          top: 40px;
          right: 1%;
          z-index: 900;
          padding: 4px;
          background-color: #fff;
          box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
          border-radius: 8px;
          border-top: 1px solid #d3d3d3;

          @include mixins.respond(desktop) {
            right: 7%;
          }
        }

        .upNavBtnApply {
          // margin: 0 24px;
          display: flex;
          align-items: center;
          background-color: #dd1134;
          // padding: 6px 24px;
          padding: 8px 18px;
          border-radius: 8px;
          height: 34px;

          &:hover {
            cursor: pointer;
          }

          // img {
          //     width: auto;
          //     height: 28px;
          // }

          h3 {
            color: #fff;
            font-size: 15px;
            font-weight: 400;
          }
        }
      }
      // end
    }
  }
}

.bottomNavBg {
  z-index: 800;
  position: sticky;
  top: 0;
  box-shadow: 0px 1px 4px #3a3a3a;
  width: 100%;
  // height: auto;
  // height: 100px;
  // position: -webkit-sticky!important;
  // position: sticky!important;
  // z-index: 100!important;
  // top: 0!important;
  padding: 6px 0;
  // display: flex;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  // display: none;

  @include mixins.respond(tablet) {
    display: none;
  }

  .bottomNavContainer {
    max-width: 90vw;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 0;

    @include mixins.respond(desktop) {
      max-width: 80vw;
    }

    @include mixins.respond(lgdesktop) {
      max-width: 80vw;
    }

    img {
      height: 54px;
      width: auto;
    }

    .upNavBankingHour {
      // color: green;
      // font-weight: 500;
      display: flex;
      align-items: center;

      p {
        color: #008000;
        padding-left: 6px;
        font-size: 16px;
        font-weight: 500;
      }
    }

    .bottomNavTextContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0 55px;
      cursor: pointer;

      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0 6px;

        img {
          height: 26px;
          width: auto;
        }

        p {
          font-size: 16px;
          color: #0f2137;
          font-weight: 500;
        }

        .lg-nav-arrow {
          -webkit-transition: -webkit-transform 0.3s ease-in-out;
          -ms-transition: -ms-transform 0.3s ease-in-out;
          transition: transform 0.3s ease-in-out;
        }
      }

      .mega-nav-container {
        position: absolute;
        // top: -250px;
        // position: fixed;
        // top: 10px;
        // left: 10px;
        left: 8vw;
        width: 84vw;
        height: 350px;
        margin: 0 auto;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        border-radius: 0 0 8px 8px;
        border-top: 1px solid #d3d3d3;

        .container {
          width: 84vw;
          height: 350px;
          padding: 12px 12px 26px 26px;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;

          .content-box {
            display: flex;
            flex-wrap: wrap;
            // align-items: center;
            // justify-content: flex-start;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            height: 100%;
            // grid-template-rows: repeat(4, minmax(0, 1fr));
            // grid-auto-flow: column;

            gap: 12px 80px;

            h4 {
              color: #003399;
              font-size: larger;
              margin-bottom: -4px;
            }

            a {
              width: 90%;
              // display: inline-block;
              // margin-bottom: 100px;
              // flex-basis: 10%;
              text-decoration: none;
              color: #0f2137;
              word-wrap: break-word;
              transition: transform 200ms ease-in-out;

              &:hover {
                color: #003399;
                font-weight: 500;
                transform: translateX(2px);
              }
            }
          }

          .megaImage {
            width: auto !important;
            height: 95% !important;
          }
        }
      }

      @keyframes megaSlideInDown {
        from {
          transform: translate3d(0, -20%, 0);
          visibility: visible;
        }

        to {
          transform: translate3d(0, 0, 0);
        }
      }

      @keyframes megaSlideOutUp {
        from {
          transform: translate3d(0, 0, 0);
        }

        to {
          visibility: hidden;
          transform: translate3d(0, -100%, 0);
        }
      }

      .mega-menu-transition-class-enter {
        // z-index: 500;
        opacity: 0;
        z-index: 10;
        // transform: scale(0.9);
      }
      .mega-menu-transition-class-enter-active {
        // z-index: 500;
        opacity: 1;
        // transform: translateX(0);
        // transition: opacity 300ms, transform 300ms;
        animation: megaSlideInDown 500ms;
      }
      .mega-menu-transition-class-exit {
        opacity: 1;
      }
      .mega-menu-transition-class-exit-active {
        opacity: 0;
        transform: scale(0.9);
        transition: opacity 300ms, transform 300ms;
        // animation: megaSlideOutUp 500ms;
      }
    }
  }
}

// }

.MobileNavBarContainer {
  display: none;
  @include mixins.respond(tablet) {
    display: block;
  }

  .MobileDrawerContainer {
    width: 70vw !important;
    background-color: #fdfdfd !important;
    overflow: scroll;

    img {
      position: sticky;
      top: 0;
      width: 100%;
      height: auto;
      z-index: 999;
    }

    .mobile-menu-container {
      .css-1tyynle-MuiPaper-root-MuiAccordion-root.Mui-expanded {
        margin: 0 !important;
      }

      .css-1tyeb6h.Mui-expanded {
        margin: 0 !important;
      }

      .drawerLangContainer {
        padding: 12px 18px;
        display: flex;
        align-items: center;
        gap: 18px;

        p {
          font-size: 18px;
          font-weight: 500;
          color: #003399;
        }

        .css-1p7rp0n-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
          border: 1px solid #33333390 !important;
          border-radius: 6px !important;
        }

        .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
          color: #3a3a3a !important;
        }
      }

      .mobile-nav-accordion-details-container {
        a {
          display: block;
          text-decoration: none;
          font-size: 16px;
          color: #0f2137;
          padding: 6px 0;
        }
      }

      .singleLink {
        p {
          font-size: 15px;
          font-weight: 500;
          color: #003399;
          padding: 12px 0;
          margin-left: 15px;
        }
        border-bottom: 1px solid #00000030;
      }
    }
  }

  .mobileNavImageContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px 10px 20px;
    background-color: #f2f2f2;
    position: fixed;
    top: 0;
    z-index: 99;

    .mobileNavLogoImage {
      height: 50px;
      width: auto;
    }

    .upNavBankingHour {
      display: flex;
      align-items: center;
      gap: 6px;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}

/* === Appear Transition === */
// .mega-menu-transition-class-appear {
//     opacity: 0;
// }

// .mega-menu-transition-class-appear-active {
//     opacity: 1;
//     transition: opacity 1000ms;
// }

/* === Enter Transition === */
// .mega-menu-transition-class-enter {
//     opacity: 0;
// }

// .mega-menu-transition-class-enter-active {
//     opacity: 1;
//     transition: opacity 1000ms;
// }

// .mega-menu-transition-class-enter-done {
//     opacity: 1;
// }
/* === Exit Transition === */
// .mega-menu-transition-class-exit {
//     opacity: 1;
// }

// .mega-menu-transition-class-exit-active {
//     opacity: 0;
//     transition: opacity 1000ms;
// }

// .mega-menu-transition-class-exit-done {
//     opacity: 0;
// }
.grivance_main {
  position: relative;

  &:hover {
    .nav__submenu {
      display: block;
    }
  }

  .nav__submenu {
    z-index: 999;
    font-weight: 400;
    text-transform: none;
    display: none;
    position: absolute;
    left: -40px;
    width: 180px;
    background-color: #fdfdfd;
    box-shadow: 0px 4px 10px #3a3a3a;

    .nav__submenu-item {
      list-style: none;
      &:hover {
        background-color: #dd1134;

        a {
          color: #fff;
        }
      }
      a {
        display: block;
        padding: 8px 8px;
        line-height: inherit;
        cursor: pointer;
        /* color: #3a3a3a;
        font-size: var(--font-xsm);
        font-weight: 500; */
        color: #3a3a3a;
        font-size: var(--font-xsm);
        font-weight: 400;
      }
    }

    .nav__submenu-item > a {
      display: block;
      padding: 8px 8px;
      line-height: inherit;
      cursor: pointer;
      /* color: #3a3a3a;
    font-size: var(--font-xsm);
    font-weight: 500; */
      color: #3a3a3a;
      font-size: var(--font-xsm);
      font-weight: 500;
    }
  }
}
