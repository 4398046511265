@use "src/constants/styles/mixins.scss";

.banner-carousel {
  @include mixins.respond(tablet) {
    margin-top: 74px;
  }
}

.landingBannerVidContainer {
  position: relative;
  top: 0;
  left: 0;

  video {
    width: 100%;
    height: 85vh;
    object-fit: cover;
    position: relative;
    top: 0;
    left: 0;

    @include mixins.respond(tablet) {
      width: 100%;
      height: auto;
    }
  }

  .vid-overlay {
    position: absolute;
    background-color: transparent;
    top: 0;
    width: 100%;
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mixins.respond(lgdesktop) {
      width: 100%;
      height: 800px;
    }

    @include mixins.respond(desktop) {
      width: 100%;
      height: 760px;
    }

    @include mixins.respond(tablet) {
      width: 100%;
      height: 500px;
    }

    @include mixins.respond(mobile) {
      width: 100%;
      height: 200px;
    }

    .video-play-pause-btn {
      position: relative;
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 50%;
      padding: 26px;
      font-size: 162px;
      cursor: pointer;

      // @include mixins.respond(desktop) {
      //     width: 100%;
      //     height: 800px;
      // }

      @include mixins.respond(tablet) {
        padding: 14px;
        font-size: 100px;
      }

      &:hover {
        scale: 1.2;
      }

      &:focus {
        scale: 1.2;
      }
    }
  }

  .vid-controls {
    position: absolute;
    top: 3%;
    right: 3%;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;

    &:hover {
      scale: 1.1;
    }

    &:focus {
      scale: 1.1;
    }
  }
}

.landingBannerContainer {
  position: relative;
  top: 0;
  left: 0;

  .landingBanner {
    width: 100%;
    // height: 85vh;
    height: auto;
    // object-fit: cover;
    position: relative;
    top: 0;
    left: 0;

    @include mixins.respond(tablet) {
      width: 100%;
      height: auto;
    }
  }

  .landingBannerForwardTextContainer {
    position: absolute;
    top: 15%;
    left: 3%;
    background-color: #fff;
    padding: 28px 38px 28px 58px;
    width: 520px;
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    .leftHalfBorder {
      position: relative;
      border-radius: 0;
    }

    .leftHalfBorder::before {
      content: "";
      width: 10px;
      height: 100%;
      width: 12px !important;
      height: 180px !important;
      background-color: #003399;
      position: absolute;
      left: -58px;
      top: 24px;
    }

    h2 {
      font-family: "Roboto", sans-serif;
      font-size: 38px;
      font-weight: 700;
      color: #0f2137;
      padding: 6px 0 16px 0;
    }

    h4 {
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-weight: 500;
      color: #0f2137;
      padding-bottom: 16px;
    }

    .landingBannerBtn {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #003399;
      padding: 12px 26px;
      width: 172px;
      height: auto;
      border-radius: 25px;
      color: #fff;
      font-size: 17px;
      font-weight: 400;
      margin: 12px 0;
    }
  }
}
