.calculatorPageMainContainer{
    .tabs{
        .tabList{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            gap: 16px 30px;
            margin-bottom: 38px;

            .button{
                padding: 7px 22px; 
                font-size: 17px;
                font-weight: 500;
                color: #0F2137;
                background-color: transparent;
                border: 2px solid #dd1134;
                border-radius: 25px;
                cursor: pointer;
                transition: transform 250ms ease-in-out; 

                &::marker{
                    content: '';
                }

                &:hover{
                    color: #fff;
                    background-color: #dd1134;
                    border: 2px solid #dd1134;
                    transform: translateY(-2px);
                }
            }
            
            .react-tabs__tab--selected{
                color: #fff;
                background-color: #dd1134;
                border-radius: 25px;

                &:focus-visible{
                    outline: none;
                }

                &::marker{
                    content: '';
                }
            }   
        }
    }
}