@use 'src/constants/styles/mixins.scss';

.branchesPageTabContainer{
    .branchesPageTabsButtonContainer{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 16px 30px;
        margin-bottom: 44px;
        margin-top: -20px;
    
        @include mixins.respond(mobile) {
            margin-top: 20px;
        }

        .branchesPageTabButton{
            padding: 7px 22px; 
            font-size: 18px;
            font-weight: 500;
            color: #0F2137;
            background-color: transparent;
            border: 2px solid #dd1134;
            border-radius: 25px;
            cursor: pointer;
            transition: transform 250ms ease-in-out;  
            
            @include mixins.respond(tablet) {
                font-size: 15px;
            }

            &:hover{
                color: #fff;
                background-color: #dd1134;
                border: 2px solid #dd1134;
                transform: translateY(-2px);
            }

            &::marker{
                content: '';
            }
        }

        .react-tabs__tab--selected{
            color: #fff;
            background-color: #dd1134;
            border-radius: 25px;

            &:focus-visible{
                outline: none;
            }

            &::marker{
                content: '';
            }
        }   
          

    }

    .branchesPageContainer{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    
        @include mixins.respond(tablet) {
            flex-direction: column-reverse;
            gap: 64px;
        }
    
        .branchesListContainer{
            width: 30%;
        
            @include mixins.respond(tablet) {
                width: 100%;
            }
        
            .branchSearchContainer{
                span{
                    margin-left: 12px;
                    color: #0f2137;
                    font-size: 15px;
                }
                
                label{
                    color: #3a3a3a;
                    font-size: 15px;
                    font-weight: 400;
                    margin-bottom: 6px;
                    margin-left: 20px;
                    align-self: auto;
                }
                
                input{
                    display: block;
                    margin: 0 auto 16px auto;
                    padding: 10px;
                    font-size: 15px;
                    color: #0f2137;
                    background-color: #f2f2f2;
                    /* width: 33%; */
                    width: 90%;
                    border-radius: 8px;
                    border: 1px solid #c2c2c2;
                }
                
                input:focus{
                    // border: 2px solid #003399;
                    -webkit-box-shadow:inset 0px 0px 0px 2px #003399;
                    -moz-box-shadow:inset 0px 0px 0px 2px #003399;
                    box-shadow:inset 0px 0px 0px 2px #003399;
                }
            }        
        
            .branchesAccordianContainer{
                height: 60vh;
                overflow-y: scroll;    
                .css-1tyynle-MuiPaper-root-MuiAccordion-root.Mui-expanded{
                    margin: 0!important;
                }
                .css-1tyeb6h.Mui-expanded{
                    margin: 0!important;
                }
        
                .branch-accordion-details-container{
                    margin-top: -8px;
                    padding-left: 12px;
                    p{
                        margin: 6px 0;
                        font-size: 15px;
                        color: #0f2137;
        
                        span{
                            color: #003399;
                            cursor: pointer;
                            font-weight: 500;
                        }
                    }
                }
        
            }
        }
        
        
        .branchesMapContainer{
            width: 68%;
        
            @include mixins.respond(tablet) {
                width: 100%;
            }
        }
    }

}




