@use 'src/constants/styles/mixins.scss';
@import 'src/constants/styles/variables.scss';

.InterestPageTableContainer{
    max-width: 70vw;
    margin: 0 auto;
    font-family: $font-dm!important;

    @include mixins.respond(tablet) {
        max-width: none;
        width: 100%;
    }

    .InterestPageTableRow{
        &:hover{
            background-color: rgba(173, 216, 230, 0.5);
        }
    }

    .InterestPageTableDateText{
        font-size: 16px!important;
        color: #696969!important;
        font-weight: 600!important;
    }

    .InterestPageTableTitleText{
        font-size: 17px!important;
        color: #36454F!important;
        font-weight: 600!important;
        transition: transform 250ms ease-in-out;

        &:hover{
            cursor: pointer;
            transform: translateY(-1px);
        }
    }

    .InterestPageTableActionsButton{
        border: none;
        font-size: 16px!important;
        font-weight: 600;
        color: #36454F!important;
        transition: transform 250ms ease-in-out!important;
        padding: 0!important;
        display: flex;
        justify-content: right;
        align-items: center;
        gap: 4px;

        &:hover{
            cursor: pointer;
            transform: translateY(-2px);
            color: #003399!important;
        }
    }
    

    

}