@use 'src/constants/styles/mixins.scss';


.forexComponentContainer{
    margin-top: 44px;

    .BlogsContentBox{
        position: relative;
        height: 50vh;

        @include mixins.respond(desktop) {
            height: 35vh;
        }

        @include mixins.respond(lgdesktop) {
            height: 35vh;
        }

        @include mixins.respond(tablet) {
            height: 30vh;
        }

        @include mixins.respond(mobile){
            height: 40vh;
        }

        &::before{
            content: '';
            width: 100%;
            height: 220px;
            background-image: linear-gradient(rgb(72,0,72,0.7), rgb(192,72,72,0.7)), url('../../assets/forexbg.webp');
            // background-image: linear-gradient(to right, #1a298080, #26d0ce80), url('../../assets/forexbg03.jpg');
            
            background-repeat:no-repeat ;
            background-size: cover;
            position: absolute;
        }

        .view-all{
            color: #fff;
            font-size: 17px;
            position: absolute;
            z-index: 90;
            left: 3%;
            top: 6%;

            &:hover{
                text-decoration: underline;
            }
            

            @include mixins.respond(tablet) {
                display: none;
            }
        }

        .slogun{
            text-align: center;
            padding: 35px 0px;
            color: #fff;
            position: relative;
            // margin-bottom: 2%;

            h2{
                font-size: 36px;
                font-weight: 500;
                margin-bottom: 12px;
            }

            p{
                font-size: 18px;

                span{
                    font-size: 16px;
                    a{
                        color: #fff;
                    }
                }
            }

            a{
                display: none;
                font-weight: 500;
                color: #fff;
                // transition: transform 300ms ease-in-out;

                &:hover{
                    // transform: translateY(4px)
                    // color: #dd1134;
                    font-weight: 600;
                }

                @include mixins.respond(tablet) {
                    display: block;
                }
            }
        }

        .BBoxContent{
            position: relative;
            max-width: 85vw;
            margin: 0 auto;
            // display: flex;
            // justify-content: space-around;
            // align-items: center;
            // top: 150px;
            /* margin-top: 10px; */

            @include mixins.respond(tablet) {
                max-width: 95vw;
            }

            .forex-carousel-provider{
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 42px;

                .sliderContainer{
                    position: relative;
                    width: 100%;
                    margin: auto;

                    .cus-back{
                        position: absolute;
                        top: 50%;
                        left: -10px;
                        transform: translateY(-50%);
                        background-color: transparent!important;
                        border: none;
                        border-radius: 50%;

                        @include mixins.respond(tablet) {
                            display: none;
                        }

                        .btn-left{
                            color: #dd1134;
                            height: 32px;
                            width: 32px;

                            &:hover{
                                color: #b60b27;
                            }
                        }
                    }

                    .cus-next{
                        position: absolute;
                        top: 50%;
                        right: -15px;
                        transform: translateY(-50%);
                        background-color: transparent!important;
                        border: none;
                        border-radius: 50%;

                        @include mixins.respond(desktop) {
                            right: 30px;
                        }

                        @include mixins.respond(lgdesktop) {
                            right: 30px;
                        }

                        @include mixins.respond(tablet) {
                            display: none;
                        }

                        .btn-right{
                            color: #dd1134;
                            height: 32px;
                            width: 32px;

                            &:hover{
                                color: #b60b27;
                            }
                        }
                    }

                    .homeForexSlide{
                        background-color: "#000";
                        width: 213.33px;
                        height: 120px;
                        background-color: #fff;
                        border: 3px solid #dd1134;
                        border-radius: 6px;
                        margin: 0 12px;
                        padding: 14px;
    
                        @include mixins.respond(mobile) {
                            margin: 0 auto;
                        }
    
                        .flagRow{
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 10px;
    
                            p{
                                font-size: 16px;
                                font-weight: 600;
                                color: #003399;
                            }
                        }
    
                        .priceRow{
                            margin-top: 12px;
                            display: flex;
                            align-items: flex-start;
                            justify-content: space-around;
                            
                            .priceCol{
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                gap: 4px;
    
                                h4{
                                    color: #0f2137;
                                }
    
                                p{
                                    color: #000;
                                }
                            }
                        }
    
                    }
                
                }


            }
        
        }
    }
}
