.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
	padding-right: 32px !important;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
	padding: 10px 8px !important;
}

.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
	color: #fff !important;
}
	
.css-bpeome-MuiSvgIcon-root-MuiSelect-icon {
	color: #fff !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
	border: none !important;
}

.css-1636szt {
	color: #fff !important;
}

.css-igs3ac {
	border: none !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.loaderContainer {
	width: 100%;
	height: 75vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 18px 0;
}

/* Animated Circles CSS */

.circles {
	position: relative;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
}

.circles li {
	position: relative;
	display: block;
	list-style: none;
	width: 20px;
	height: 20px;
	background: rgba(214, 27, 36, 0.1);
	animation: animate 25s linear infinite;
	bottom: calc(-150px - 40vh);
}

.circles li:nth-child(1) {
	left: 25%;
	animation-delay: 0s;
	animation-duration: 20s;
	background: red;
	width: 19px;
	height: 33px;
	border: 10px solid red;
	border-left: 0;
	border-top: 0;
	transform: rotate(45deg);
}

.circles li:nth-child(2) {
	left: 10%;
	animation-delay: 0s;
	animation-duration: 25s;
	background: red;
	width: 50px;
	box-sizing: content-box;
	height: 25px;
	border-color: #333;
	border-style: solid;
	border-width: 2px 2px 26px 2px;
	border-radius: 100%;
	position: relative;
}

.circles li:nth-child(2):before {
	content: "";
	position: absolute;
	top: 50%;
	left: 0;
	background: red;
	border: 9.4px solid red;
	border-radius: 100%;
	width: 6.25px;
	height: 6.25px;
	box-sizing: content-box;
}

.circles li:nth-child(2):after {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	background: red;
	border: 9.4px solid red;
	border-radius: 100%;
	width: 6.25px;
	height: 6.25px;
	box-sizing: content-box;
}

.circles li:nth-child(3) {
	left: 70%;
	width: 20px;
	height: 20px;
	animation-delay: 0s;
	animation-duration: 20s;
	border-radius: 50%;
	border-top-left-radius: 0 !important;
	background: #2e21e01a;
}


@keyframes animate {
	0% {
		transform: translateY(0) rotate(0deg);
		opacity: 0;
	}

	50% {
		transform: translateY(-60vh) rotate(360deg);
		opacity: 1;
	}

	100% {
		transform: translateY(-120vh) rotate(720deg);
		opacity: 0;
	}
}


@keyframes slideInDown {
	from {
		transform: translate3d(0, -100%, 0);
		visibility: visible;
	}

	to {
		transform: translate3d(0, 0, 0);
	}
}

/* .slideInDown {
	animation-name: slideInDown;
  } */


@keyframes slideOutUp {
	from {
		transform: translate3d(0, 0, 0);
	}

	to {
		visibility: hidden;
		transform: translate3d(0, -100%, 0);
	}
}

.slideOutUp {
	animation-name: slideOutUp;
}

.fluidgrid {
	/* background: #4030c52e; */
	padding: 10px;
	position: relative;
	/* height: 325px; */
	display: inline-block;
	margin-top: 9px !important;
}



section.main_cont {
	max-width: 1170px;
	width: 100%;
	margin: 2% auto;
	padding: 2px 20px;
	min-height: 50vh;
}

@media only screen and (min-width: 1501px) and (max-width: 1921px) { 
	section.main_cont {
		max-width: 80vw;
	}
}

@media only screen and (min-width: 1922px) and (max-width: 2400px) { 
	section.main_cont {
		max-width: 80vw;
	}
}

.box-item-news {
	width: 90%;
	margin: auto;
	border: 1px solid #f9f9f9;
	box-sizing: border-box;
	box-shadow: 0px 2px 10px 2px #ddd;
	margin-bottom: 10%;
	border-radius: 10px;
	overflow: hidden;
}



.content-news p {
	line-height: 24px;
	margin-bottom: 20px;
	margin-top: 20px;
}

.content-news {
	min-height: 150px;
	max-height: 150px;
}

.img-news {
	min-height: 200px;
	max-height: 200px;
	overflow: hidden;
}

.img-news img {
	object-fit: cover;
	width: 100%;
	height: 200px;
	transition: ease-in-out 0.5s;
}


.page-title {
	background-image: linear-gradient(to top, #fff, #eee);
	padding: 2% 8%;
}

@media (max-width: 800px) { 
	.page-title {
		padding-top: 86px;
	}
}


.page-title h1 {
	font-family: "DM Sans", sans-serif;
	color: #dd1134;
	font-size: 32px;
	font-weight: 600;
}

.page-title ol li:first-child a {
	color: #0f2137;
}

.page-title ol li a {
	text-decoration: none;
}

.content-news {
	padding: 20px;
}

.content-news h1 a {
	color: inherit;
	text-decoration: none;
}

.box-item-news:hover .content-news h1 {
	color: #dd1134;
	cursor: pointer;
}

.box-item-news:hover .img-news img {
	scale: 1.1;
}


.detail_content img {
	width: 80%;
	max-height: 324px;
	object-fit: cover;
	display: block;
	margin: 0 auto;
	border-radius: 16px;
	margin-bottom: 38px;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
}

.detail_content-child .marginTop{
	margin-top: 24px;
}

.detail_content-child > .external-button{
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	border: 2px solid #dd1134;
	border-radius: 50px;
	width: 146px;
	padding: 6px 32px;
	margin-top: 26px;
}

.detail_content-child > .external-button:hover {
	background-color: #dd1134;
	cursor: pointer;
}

.external-button > p{
	color: #dd1134;
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 0.5px;
}

.external-button:hover > p{
	color: #fff;
}




.digital-external-button{
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: transparent;
	border: 2px solid #dd1134;
	border-radius: 50px;
	width: 174px;
	padding: 6px 32px;
	margin-top: 26px;
}

.digital-external-button:hover {
	background-color: #dd1134;
	cursor: pointer;
}

.digital-external-button > p{
	color: #dd1134;
	font-size: 16px;
	font-weight: 500;
	letter-spacing: 0.5px;
}

.digital-external-button:hover > p{
	color: #fff;
}

.careerImgCont .careerImg {
	display: block;
	width: 600px;
	max-height: none!important;
	height: 100%!important;
	object-fit: contain!important;
	margin-bottom: 20px;
	margin: 0 auto;
}

@media (max-width: 480px) { 
	.careerImgCont .careerImg {
	width: 100%;
	}
 }

.detail_content p {
	text-align: justify;
	line-height: 30px;
}

span.date_news {
	font-size: 18px;
	font-style: italic;
	color: #8f8383;
}

table {
	width: 100%;
	/* border: 1px solid #ddd; */
	/* padding: 11px; */
}

tr {
	border: 1px solid #ddd;
}

th {
	border: 1px solid #ddd;
	padding: 12px;
}

td {
	border: 1px solid #ddd;
	padding: 10px;
}

.paginationContainer{
	display: flex;
	justify-content: center;
	align-items: center;
}

.scroll-to-top{
	/* background-color: #f2f2f2!important; */
	bottom: 34px!important;
	right: 16px!important;
}

.homeCalcContainer{
	v-width: 90vw;
	margin: 0 auto;
	/* padding: 24px 0; */
}

/* --------- */
/*Scrollbar   */
/* --------- */

#customScroll ::-webkit-scrollbar {
    width: 8px;
}
  
  /* Track */
#customScroll ::-webkit-scrollbar-track {
    background: #c2c2c290;
}
  
  /* Handle */
#customScroll ::-webkit-scrollbar-thumb {
    background: #003399;
    border-radius: 12px;
}
  
  /* Handle on hover */
#customScroll ::-webkit-scrollbar-thumb:hover {
    background: #003399;
    border-radius: 12px;
}

/* audio player */
     /* input[type="range"] {
        --range-progress: 15%;
      }
      
	input[type="range"]::before {
        background: #f50;
        width: var(--range-progress);
    } */

.branchPageSelectContainer{
	width: 90%;
	padding-bottom: 8px;
	/* margin-left: 14px; */
	margin: 0 auto;
}

.branchPageSelectContainer label{
	color: #3a3a3a;
	margin-bottom: 4px;
	font-weight: 400;
	font-size: 15px;
}
	  
.branchPageSelectContainer .basic-single{	
	margin-bottom: 2px;
}

@keyframes float {
	0% {
		/* box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6); */
		transform: translatey(0px);
	}
	50% {
		/* box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2); */
		transform: translatey(-20px);
	}
	100% {
		/* box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6); */
		transform: translatey(0px);
	}
}


.customLoaderContainer{
	width: 100%;
	height: 75vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 16px 0;
}

.customLoaderContainer .logo{
	height: 100px;
	transform: translatey(0px);
	animation: float 6s ease-in-out infinite;
}

.noPageFoundBackButton{
    padding: 8px 16px;
    background-color: #003399;
    color: #fff;
    border-radius: 25px;
}

.noPageFoundMainContainer{
    max-width: 85vw;
    margin: 80px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.noPageFoundImage{
    margin-top: 35px;
    max-height: 320px;
}

.noPageFoundText{
    margin-top: 32px;
    font-size: 20px;
    color: #0f2137;
    text-align: center;
    max-width: 500px;
}

.scrollTopComp{
	transition: transform 250ms ease-in-out!important; 
	margin-bottom: 44px!important;
	border-radius: 50px!important;
}

.scrollTopComp:hover{
	cursor: pointer;
	background-color: #dd1134!important;
	transform: translateY(-6px)!important;
}

.scrollTopComp:hover .scrollIcon{
	color: #fff!important;
}

.scrollTopComp .scrollIcon{
	color: #353935!important;
}


.fb_dialog_content iframe{
	right: 0px!important;
}



/* search page start */

.searchDialogResultContainer{
    /* max-width: 85vw; */
    /* margin: 0 auto; */
    display: grid;
    grid-template-columns: repeat(4, 1fr);
	grid-auto-rows: 1fr;
    justify-items: flex-start;
	align-content: space-between;
    grid-column-gap: 32px;
	grid-row-gap: 32px;

}

.searchResultsItem{
    border: 1px solid #f2f2f2;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    padding: 20px 14px;
    width: 100%;
    height: 100%;
}

.searchResultsItem:hover{
    cursor: pointer;
    background-color: #f2f2f2;
}

.searchResultsItem > span {
    background-color: #003399;
    padding: 3px 10px;
    border-radius: 15px;
    color: #fff;
    font-size: 15px;
}

.searchResultItemTitle{
    font-size: 18px;
    font-weight: 500;
    margin: 12px 0 6px 0;
    color: #0f2137;
    display: block;
}

.searchResultKnow{
    font-size: 15px;
    font-weight: 600;
    color: #333;
}

.searchKnowContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
    transition: transform 250ms ease-in-out;
}

.serviceKnowImg {
    width: auto;
    height: 17px;
}

.searchKnowContainer:hover{
    transform: translateX(4px);
}

.searchPageConditionContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.searchPageConditionImage{
    max-height: 280px;
}

.searchPageConditionText{
    font-size: 18px;
    color: #0f2137;
    text-align: center;
}

.searchSubmitButton{
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    gap: 0 2px!important;
}

/* mobile devices */
@media only screen and (min-width: 320px) and (max-width: 480px) {
    .dialogSearchInput{
        width: 60vw!important;
    }

    .searchPageMainContainer{
        max-width: 95vw;
    }

    .searchDialogResultContainer{
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 16px;
    }
}


@media only screen and (min-width: 500px) and (max-width: 821px) {
    .searchDialogResultContainer{
        grid-template-columns: repeat(3, minmax(0, 1fr));
    
    }
}

/* search page end */




/* select */
.compound-time-select{
	min-width: 240px;
}

/* select */

@media only screen and (min-width: 800px) and (max-width: 1400px) {
	.fb_iframe_widget iframe{
		height: 350px!important;
	}
}

/* mobile devices */
@media only screen and (min-width: 320px) and (max-width: 480px) {
    .noPageFoundImage{
        margin-top: 100px;
        max-height: 200px;
    }

    .noPageFoundText{
        margin-top: 10px;
    }
}




	  


