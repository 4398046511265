@use "src/constants/styles/mixins.scss";
@import "src/constants/styles/variables.scss";

.teamsPageMainContainer {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 80px 102px;
  justify-items: center;
  align-content: space-between;
  // place-items: center;

  @include mixins.respond(tablet) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 74px;
  }

  @include mixins.respond(mobile) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .teams-profile-container {
    width: 260px;

    .team-image-box {
      width: 200px;
      //   height: 200px;
      overflow: hidden;
      box-shadow: blue 0px 0px 0px 2px inset,
        rgb(255, 255, 255) 10px -10px 0px -3px, rgb(255, 85, 85) 10px -10px,
        rgb(255, 255, 255) 20px -20px 0px -3px, rgb(0, 51, 153) 20px -20px,
        rgb(255, 255, 255) 30px -30px 0px -3px;

      .blurhash-wrapper {
        height: 250px;
        width: 250px;
        margin: 0 auto;
        position: relative;
        overflow-y: hidden;

        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 200px;
          height: auto;
          //   width: 100%;
          //   height: 100%;
          //   object-fit: contain;
          // image-rendering: auto;
        }
      }
    }
    h3 {
      font-family: $font-dm;
      color: $blue-color;
      text-align: left;
      font-size: 20px;
      font-weight: 600;
      margin-top: 8px;
      margin-bottom: 2px;
    }

    h4 {
      font-family: $font-dm;
      text-align: left;
      color: #3a3a3a;
      margin-top: 2px;
      font-weight: 600;
      font-size: 18px;
    }
  }
}

.teams-block {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // display: block;
  max-width: 30%;
  display: block;
  margin: 0 auto 76px auto;
  .chairmain-details {
    text-align: center;
    margin-top: 10px;

    h3 {
      font-family: $font-dm;
      color: $blue-color;
      //   text-align: left;
      font-size: 20px;
      font-weight: 600;
      margin-top: 8px;
      margin-bottom: 2px;
    }

    h4 {
      font-family: $font-dm;
      //   text-align: left;
      color: #3a3a3a;
      margin-top: 2px;
      font-weight: 600;
      font-size: 18px;
    }
  }

  @include mixins.respond(tablet) {
    max-width: 50%;
    margin: 0 auto 60px auto;
  }

  @include mixins.respond(mobile) {
    max-width: 90%;
    display: block;
    margin: 24px auto 70px auto;
  }
  .teams-block-profile-container {
    width: 200px;
    max-height: 400px;
    margin: 0 auto;
    overflow: hidden;
    box-shadow: blue 0px 0px 0px 2px inset,
      rgb(255, 255, 255) 10px -10px 0px -3px, rgb(255, 85, 85) 10px -10px,
      rgb(255, 255, 255) 20px -20px 0px -3px, rgb(0, 51, 153) 20px -20px,
      rgb(255, 255, 255) 30px -30px 0px -3px;

    @include mixins.respond(mobile) {
    }

    // height: 330px;
    // margin: 0 auto;
    // width: 30%;

    .blurhash-wrapper {
      height: 250px;
      width: 250px;
      margin: 0 auto;
      position: relative;
      // border-radius: 20px;
      // overflow: hidden;
      overflow-y: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 200px;
        height: auto;

        @include mixins.respond(mobile) {
          width: 200px;
          height: 200px;
        }
      }
    }

    h3 {
      font-family: $font-dm;
      color: $blue-color;
      text-align: left;
      font-size: 20px;
      font-weight: 600;
      margin-top: 8px;
      margin-bottom: 2px;
    }

    h4 {
      font-family: $font-dm;
      text-align: left;
      color: #3a3a3a;
      margin-top: 2px;
      font-weight: 600;
      font-size: 18px;
    }
  }
}
