@use "src/constants/styles/mixins.scss";

.officersPageTabContainer {
  .officersPageTabsButtonContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 16px 30px;

    .officersPageTabButton {
      padding: 7px 22px;
      font-size: 18px;
      font-weight: 500;
      color: #0f2137;
      background-color: transparent;
      border: 2px solid #dd1134;
      border-radius: 25px;
      cursor: pointer;
      transition: transform 250ms ease-in-out;

      @include mixins.respond(tablet) {
        font-size: 15px;
      }

      &:hover {
        color: #fff;
        background-color: #dd1134;
        border: 2px solid #dd1134;
        transform: translateY(-2px);
      }

      &::marker {
        content: "";
      }
    }

    .react-tabs__tab--selected {
      color: #fff;
      background-color: #dd1134;
      border-radius: 25px;

      &:focus-visible {
        outline: none;
      }

      &::marker {
        content: "";
      }
    }
  }
}

.officers-grid-container {
  margin: 32px auto;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  justify-items: flex-start;
  align-content: space-between;
  grid-row-gap: 42px;
  grid-column-gap: 86px;
  // gap: 38px 0;
  // height: 100%;

  @include mixins.respond(tablet) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-row-gap: 42px;
    grid-column-gap: 58px;
  }

  @include mixins.respond(mobile) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-row-gap: 26px;
  }

  @include mixins.respond(desktop) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-row-gap: 46px;
  }

  @include mixins.respond(lgdesktop) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-row-gap: 46px;
  }

  .officer {
    width: 100%;
    height: 100%;

    .branch {
      background-color: #003399;
      border-radius: 8px 8px 0 0;
      padding: 10px 18px;

      h3 {
        color: #fff;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.5px;
      }
    }

    .paper {
      // border: 1px solid #000;
      border-radius: 0 0 8px 8px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      padding: 16px;

      .detail-container {
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        img {
          align-self: center;
          margin: 0 auto;
          width: 200px;
          height: 200px;
          object-fit: contain;
          border-radius: 50%;
          box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
            rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
            rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        }

        .officer-name {
          margin: 14px 0 7px 0;
          align-self: center;
          font-family: "Roboto", sans-serif;
          font-size: 20px;
          font-weight: 700;
          color: #003399;
        }

        .digital-name {
          margin: 7px 0 7px 0;
          text-align: center;
          align-self: center;
          font-family: "Roboto", sans-serif;
          font-size: 18px;
          font-weight: 600;
          color: #003399;
        }

        div {
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 8px;

          svg {
            color: #003399;
            font-size: 17px;
          }

          p {
            margin: 0;
            font-family: "Roboto", sans-serif;
            font-size: 16px;
            font-weight: 500;
            color: #0f2137;
          }
        }
      }
    }
  }
}
