@use 'src/constants/styles/mixins.scss';

.footerSectionBGContainer{
    background-color: #003399;
    padding: 32px 0 0 0;

    .footerSectionMainContainer{
        max-width: 90vw;
        margin: 0 auto;

        .allListContainer{
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            @include mixins.respond(tablet) {
                flex-wrap: wrap;
                justify-content: center;
                gap: 48px;
            }

            @include mixins.respond(mobile) {
                justify-content: flex-start;
                gap: 26px;
            }

            .singleListCont{
                h4{
                    font-family: 'DM Sans', sans-serif;
                    font-weight: 500;
                    color: #fff;
                    font-size: 22px;
                    padding-bottom: 18px;
                }

                .flexCont{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: left;
                    gap: 12px 0;

                    p{
                        font-family: 'DM Sans', sans-serif;
                        color: #f2f2f2;
                        font-size: 16px;
                        font-weight: 400;
                        transition: transform 200ms ease-in-out; 

                        &:hover{
                            // text-decoration: underline;
                            transform: translateX(4px);
                        }
                    }

                    a{
                        font-family: 'DM Sans', sans-serif;
                        text-decoration: none;
                        color: #f2f2f2;
                        font-size: 16px;
                        font-weight: 400;
                        cursor: pointer;
                        transition: transform 200ms ease-in-out; 

                        &:hover{
                            // text-decoration: underline;
                            transform: translateX(4px);
                        }
                    }
                }
            }
        }

        .copySectionContainer{
            padding: 42px 0 20px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include mixins.respond(tablet) {
                flex-direction: column;
                gap: 16px 0;
            }

            p{
                font-size: 16px;
                color: #f2f2f2;

                @include mixins.respond(mobile) {
                    text-align: center;
                }
             
                .redirect{
                    text-decoration: none;
                    color: #f2f2f2;
                }
            }
        }
    }
}