.lastVisitContainer{
    transition: all .25s ease-in-out;
    position: fixed;
    bottom: 14px;
    left: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(213,41,51);
    border-radius: 6px;
    // width: 240px;
    // height: 100px;
    z-index: 999;
    box-shadow: rgba(27, 12, 12, 0.2) 0px 20px 30px;
    padding: 14px;

    .content{
        p{
            text-align: center;
            color: #fff;
            font-size: 15px;
            font-weight: 400;
            margin-bottom: 6px;
        }

        a{
            font-size: 14px;
            display: block;
            text-align: center;
            color: #fff;
            text-decoration: underline;
            text-underline-offset: 3px;
            margin-bottom: 10px;
            transition: transform 150ms ease-in-out;
            
            &:hover{
                transform: translateY(-2px);
            }
        }

        .btn{
        
            margin: 0 auto;
            width: 80%;
            border-radius: 6px;
            background-color: #ffffff;
            padding: 3px 0;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover{
                cursor: pointer;
                background-color: #E5E4E2;

                p{
                   color: rgb(213,41,51); 
                }
            }

            p{
                color: #3a3a3a;
                font-weight: 500;
            }
        }
    }

}