.companyProfileCKContainer{
    h1{
        font-size: 22px;
        color: #003399;
        margin-bottom: 8px;
    }

    p{
        font-size: 18px;
        color: #0f2137;
        margin-bottom: 22px;
        line-height: 150%;
        letter-spacing: 0.4px;
    }
}