@use "src/constants/styles/mixins.scss";

.counter-container {
  position: absolute;
  background-color: #f9f9f9;
  border-radius: 6px;
  top: -15%;
  left: 10%;
  width: 80%;
  //   height: 160px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  padding: 38px 0;

  @include mixins.respond(lgdesktop) {
    top: -10%;
  }

  @include mixins.respond(desktop) {
    top: -10%;
  }

  @include mixins.respond(tablet) {
    top: -10%;
    flex-wrap: wrap;
    gap: 24px;
  }

  @include mixins.respond(mobile) {
    width: 90%;
    left: 5%;
    top: -20%;
  }

  .flex-item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0 24px;
    width: 20%;
    height: 100%;

    @include mixins.respond(tablet) {
      width: 40%;
      //   gap: ;
    }

    @include mixins.respond(mobile) {
      width: 90%;
    }

    .icon {
      flex-shrink: 0;
      align-self: center;
      background-color: #dd1134;
      height: 50px;
      width: 50px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        color: #fff;
        font-size: 26px;
      }
    }

    .data-flex {
      //   align-self: flex-start;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      gap: 6px;

      h3 {
        color: #3a3a3a;
        font-size: 18px;
        letter-spacing: 0.5px;
      }

      h2 {
        color: #3a3a3a;
        font-size: 22px;
      }
    }
  }
}
