@use "src/constants/styles/mixins.scss";
.homeCalcBgContainer {
  background-color: #f2f2f2;

  .homeCalcContainer {
    max-width: 95vw;
    margin: 0 auto;
    padding: 32px 0;

    @include mixins.respond(desktop) {
      max-width: 90vw;
    }

    h2 {
      font-family: "DM Sans", sans-serif;
      font-weight: bold;
      font-size: 46px;
      color: #0f2137;
      margin-bottom: 16px;

      @include mixins.respond(tablet) {
        font-size: 32px;
      }
    }

    .tabs {
      .tabList {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 16px 30px;
        margin-bottom: 38px;

        .button {
          padding: 7px 22px;
          font-size: 17px;
          font-weight: 500;
          color: #0f2137;
          background-color: transparent;
          border: 2px solid #dd1134;
          border-radius: 25px;
          cursor: pointer;
          transition: transform 250ms ease-in-out;

          &::marker {
            content: "";
          }

          &:hover {
            color: #fff;
            background-color: #dd1134;
            border: 2px solid #dd1134;
            transform: translateY(-2px);
          }
        }

        .react-tabs__tab--selected {
          color: #fff;
          background-color: #dd1134;
          border-radius: 25px;

          &:focus-visible {
            outline: none;
          }

          &::marker {
            content: "";
          }
        }
      }
    }
  }
}

.know-more-button {
  max-width: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #dd1134;
  border: 2px solid #dd1134;
  border-radius: 5px;
  padding: 8px 16px;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #dd1134;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0 50%;
    transform-origin: 0 50%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }

  &:hover,
  &:focus,
  &:active {
    color: #fff;
    // font-weight: 400;
    // font-size: 15px;
  }

  &:hover:before,
  &:focus:before,
  &:active:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    //  font-weight: 400;
    //  font-size: 15px;
  }
}
