@use 'src/constants/styles/mixins.scss';

.contactPageMainContainer{
    .top-flex-container{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        // height: 100%;

        @include mixins.respond(tablet) {
            margin-top: 24px;
        }

        
        @include mixins.respond(mobile) {
            flex-direction: column;
            margin-top: 34px;
            gap: 60px;
        }

        .flex-item{
            width: 30%;
            // min-height: 100%;
            border: 1px solid #e3e3e3;
            border-radius: 8px;
            padding: 26px;

            position: relative;

            @include mixins.respond(mobile) {
                width: 100%;
            }

            .iconContainer{
                border: 1px solid #e3e3e3;
                // padding: 32px;
                background-color: #fff;
                width: 74px;
                height: 74px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                position: absolute;
                top: -40px;
                left: 32px;

                .icon{
                    font-size: 32px;
                    color: #003399;
                    
                }
            }

            .contentContainer{
                h3{
                    color: #383e42;
                    font-size: 22px;
                    padding: 16px 0 10px 0;
                }

                p{
                    font-size: 18px;
                    color: #666;
                    line-height: 130%;
                    letter-spacing: 0.5px;
                }
            }
        }
    }

    .bottom-flex-container{
        margin-top: 64px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include mixins.respond(tablet) {
            flex-direction: column;

        }

        .formContainer{
            width: 40%;

            @include mixins.respond(tablet) {
                width: 60%;
            }

            @include mixins.respond(mobile) {
                width: 100%;
            }

            h2{
                font-size: 26px;
                color: #3a3a3a;
                font-weight: 600;
                margin-bottom: 14px;
            }

            .form{
                .contactFormInputContainer{
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 14px;
                    label{
                        color: #3a3a3a;
                        font-size: 14px;
                        font-weight: 500;
                        margin-bottom: 4px;
                        padding-left: 4px;
                    }

                    input{
                        padding: 14px;
                        font-size: 15px;
                        color: #0f2137;
                        border: 1px solid #fff!important;
                        background-color: #F4F5F8;

                        &:focus{
                            border: 1px solid #003399;
                        }


                    }

                    textarea{
                        padding: 7px 14px;
                        font-size: 16px;
                        color: #0f2137;
                        border: 1px solid #fff!important;
                        background-color: #F4F5F8;

                        &:focus{
                            border: 2px solid #003399;
                        }
                    }

                    span{
                        font-size: 14px;
                        color: #dc3545;
                        margin-top: 1px;
                    }
                }

                .captchaContainer{
                    // margin: 24px 0;
                    margin: 12px 0 24px 0;
                }

                .contactFormResetButton {
                    border-radius: 8px;
                    border-style: none;
                    box-sizing: border-box;
                    color: #FFFFFF;
                    cursor: pointer;
                    display: inline-block;
                    font-size: 15px;
                    font-weight: 500;
                    letter-spacing: 0.5px;
                    height: 40px;
                    line-height: 20px;
                    list-style: none;
                    margin: 0;
                    outline: none;
                    padding: 10px 26px;
                    position: relative;
                    text-align: center;
                    text-decoration: none;
                    transition: color 100ms;
                    vertical-align: baseline;
                    user-select: none;
                    -webkit-user-select: none;
                    touch-action: manipulation;
                  }

                  .contactSubmitButton{
                    background-color: rgba(0, 51, 153, 1);

                    &:hover{
                        background-color: rgba(0, 51, 153, 0.9);
                    }
                }
            }
        }

        .mapContainer{
            width: 50%;

            @include mixins.respond(tablet) {
                margin-top: 34px;
                width: 100%;
            }
        }
    }
}