@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Roboto:wght@100;300;400;500;700;900&display=swap');

html{
  scroll-behavior: smooth;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
*:focus {
  outline: none!important;
}

body{
  margin: 0;
  font-family: 'DM Sans', sans-serif;
}

a{
  text-decoration: none;
}

@font-face {
  font-family: "Amithen";
  src: local("Amithen"), url("./assets/fonts/Amithen.ttf") format("truetype");
  /* font-weight: 400; */
}


/* .react-tabs__tab--selected{
  color: #fff!important;
  background-color: #003399!important;
  border-radius: 25px!important;
}
.react-tabs__tab--selected:focus-visible{
  outline: none!important;
}

.react-tabs__tab--selected::marker{
  content: '';
} */


.displayNone {
  display: none;
}

/* forex start */

.mui-date{
  border: 1px solid #525151!important;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.currency-flag {
  display: inline-block;
  width: 36px;
  height: 27px;
  /* width: 64px;
  height: 48px; */
  background-size: cover;
  background-image:url('https://wise.com/public-resources/assets/flags/rectangle_fallback.png');
  border: 1px solid #c2c2c2;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.currency-flag-sm {
  width: 16px;
  height: 10px;

}

.currency-flag-lg {
  width: 36px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
}

.currency-flag-xl {
  width: 48px;
  height: 32px;
}

.currency-flag.currency-flag-AUD{background-image:url('https://demo.nyatapol.com/dashicfc/uploads/forexFlags/AUD.svg');}
.currency-flag.currency-flag-AED{background-image:url('https://demo.nyatapol.com/dashicfc/uploads/forexFlags/AED.svg');}
.currency-flag.currency-flag-BHD{background-image:url('https://demo.nyatapol.com/dashicfc/uploads/forexFlags/BHD.svg');}
.currency-flag.currency-flag-CAD{background-image:url('https://demo.nyatapol.com/dashicfc/uploads/forexFlags/CAD.svg');}
.currency-flag.currency-flag-CHF{background-image:url('https://demo.nyatapol.com/dashicfc/uploads/forexFlags/CHF.svg');}
.currency-flag.currency-flag-CNY{background-image:url('https://demo.nyatapol.com/dashicfc/uploads/forexFlags/CNY.svg');}
.currency-flag.currency-flag-DKK{background-image:url('https://demo.nyatapol.com/dashicfc/uploads/forexFlags/DKK.svg');}
.currency-flag.currency-flag-EUR{background-image:url('https://demo.nyatapol.com/dashicfc/uploads/forexFlags/EUR.svg');}
.currency-flag.currency-flag-GBP{background-image:url('https://demo.nyatapol.com/dashicfc/uploads/forexFlags/GBP.svg');}
.currency-flag.currency-flag-HKD{background-image:url('https://demo.nyatapol.com/dashicfc/uploads/forexFlags/HKD.svg');}
.currency-flag.currency-flag-INR{background-image:url('https://demo.nyatapol.com/dashicfc/uploads/forexFlags/INR.svg');}
.currency-flag.currency-flag-JPY{background-image:url('https://demo.nyatapol.com/dashicfc/uploads/forexFlags/JPY.svg');}
.currency-flag.currency-flag-KRW{background-image:url('https://demo.nyatapol.com/dashicfc/uploads/forexFlags/KRW.svg');}
.currency-flag.currency-flag-KWD{background-image:url('https://demo.nyatapol.com/dashicfc/uploads/forexFlags/KWD.svg');}
.currency-flag.currency-flag-MYR{background-image:url('https://demo.nyatapol.com/dashicfc/uploads/forexFlags/MYR.svg');}
.currency-flag.currency-flag-QAR{background-image:url('https://demo.nyatapol.com/dashicfc/uploads/forexFlags/QAR.svg');}
.currency-flag.currency-flag-SAR{background-image:url('https://demo.nyatapol.com/dashicfc/uploads/forexFlags/SAR.svg');}
.currency-flag.currency-flag-SEK{background-image:url('https://demo.nyatapol.com/dashicfc/uploads/forexFlags/SEK.svg');}
.currency-flag.currency-flag-SGD{background-image:url('https://demo.nyatapol.com/dashicfc/uploads/forexFlags/SGD.svg');}
.currency-flag.currency-flag-THB{background-image:url('https://demo.nyatapol.com/dashicfc/uploads/forexFlags/THB.svg');}
.currency-flag.currency-flag-USD{background-image:url('https://demo.nyatapol.com/dashicfc/uploads/forexFlags/USD.svg');}

/* forex end */

/* .mobileService .slider li {
  position: unset !important;
  height: unset !important;
  padding-bottom: unset !important;
}
.mobileService .slider li .carousel__inner-slide {
  position: unset!important;
} */

@media only screen and (min-width: 1501px) and (max-width: 1921px) { 
  .digitalServicesContainer .digitalFlexContainer .digitalImage .sliderContainer .cus-next{
    right: -10px!important;
  }
}


