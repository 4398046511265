@import "src/constants/styles/variables.scss";
.grievanceFormResponseAnsMainContainer {
  max-width: 80vw;
  margin: 0 auto;

  .grievanceFormResHead {
    font-size: var(--font-lg);
    font-weight: 500;
    text-align: center;
    color: #3a3a3a;
    padding-bottom: 8px;
  }
  .grievanceFormResSubHead {
    font-size: var(--font-md);
    font-weight: 500;
    text-align: center;
    color: #3a3a3a;
    padding-bottom: 28px;
  }

  .grievanceFormResIssue {
    font-size: var(--font-md);
    font-weight: 600;
    color: #a93a7c;
    padding-bottom: 8px;
  }

  .grievanceFormResIssueSubject {
    font-size: var(--font-md);
    font-weight: 500;
    color: #0f2137;
    padding-bottom: 10px;
  }

  .grievanceFormResIssueText {
    font-size: 18px;
    color: #0f2137;
    font-weight: 400;
    padding-bottom: 32px;
  }
  .grievanceFormResIssueAns {
    font-size: 18px;
    color: #0f2137;
    font-weight: 400;
    padding-bottom: 42px;
  }
}

.grievanceSubmitButton {
  background-color: #dc3545;
}
.read-or-hide {
  cursor: pointer;
  color: #dc3545;
  font-size: var(--font-sm);
  font-weight: 500;
}

/* mobile devices */
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .grievanceFormResponseAnsMainContainer {
    max-width: 90vw;
  }
}
