@use 'src/constants/styles/mixins.scss';

.ceoMessageSectionMainBgWrapper{
    position: relative;
    overflow: hidden;

    @include mixins.respond(tablet) {
        margin-top: 46px;
    }

    &:after{
        content: '';
        position: absolute;
        width: 450px;
        height: 500px;
        // background-color: rgb(144, 160, 255);
        background-image: url('../../assets/extras/ceoMsgShape.svg');
        background-repeat: no-repeat;
        background-position: center;
        // border-radius: 50%;
        // top: -10%;
        // right: -5%;
        z-index: -1;
        top: 10%; /* change value as required */
        right: -10%; /* change value as required */
    }

    .ceoMessageSectionMainContainer{
        max-width: 85vw;
        margin: 32px auto 52px auto;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        @include mixins.respond(tablet) {
            flex-direction: column;
            max-width: 90vw;
            align-items: center;
        }

        @include mixins.respond(mobile) {
            // flex-direction: column;
        }

    
        .ceoMessageSectionTextContainer{
            width: 50%;

            @include mixins.respond(tablet) {
                width: 100%;
            }
    
            h3{
                // text-align: center;
                font-family: "Amithen";
                font-weight: normal;
                font-size: 68px;
                color: #DD1134;
                margin-bottom: 12px;

                @include mixins.respond(tablet) {
                    margin-top: 28px;
                    font-size: 52px;
                    text-align: center;
                }
            }
    
            .ceoMessageCKContent{
                p{
                    font-family: 'DM Sans', sans-serif;
                    color: #0f2137;
                    font-size: 19px;
                    font-weight: 400;
                    line-height: 150%;
                    letter-spacing: 0.5px;
                }

                h3{
                    font-family: 'DM Sans', sans-serif;
                    font-weight: bold;
                    font-size: 19px;
                    color: #36454f;
                }
            }

        }
    
        img{
            width: 40%;
            height: auto;
            // box-shadow: rgba(240, 46, 170, 0.4) 5px 5px, rgba(240, 46, 170, 0.3) 10px 10px, rgba(240, 46, 170, 0.2) 15px 15px, rgba(240, 46, 170, 0.1) 20px 20px, rgba(240, 46, 170, 0.05) 25px 25px;
            // box-shadow: rgba(221, 17, 52, 0.4) 5px 5px, rgba(221, 17, 52, 0.3) 10px 10px, rgba(221, 17, 52, 0.2) 15px 15px, rgba(221, 17, 52, 0.1) 20px 20px, rgba(221, 17, 52, 0.05) 25px 25px;
    
            box-shadow: blue 0px 0px 0px 2px inset, rgb(255, 255, 255) 10px -10px 0px -3px, rgb(255, 85, 85) 10px -10px, rgb(255, 255, 255) 20px -20px 0px -3px, rgb(0, 51, 153) 20px -20px, rgb(255, 255, 255) 30px -30px 0px -3px;

            @include mixins.respond(mobile) {
                width: 100%;
            }
        }
    }
}

