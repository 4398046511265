@use 'src/constants/styles/mixins.scss';
@import 'src/constants/styles/variables.scss';

.servicePageContainer{
    max-width: 85vw;
    margin: 24px auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    @include mixins.respond(tablet) {
        flex-direction: column-reverse;
        // max-width: 90vw;
        // margin: 12px auto;
    }

    @include mixins.respond(mobile) {
        flex-direction: column;
    }

    .buttons{
        width: 20vw;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        border-radius: 0 0 16px 16px;

        @include mixins.respond(tablet) {
            width: 100%;       
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 24px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        }

        @include mixins.respond(mobile) {
            margin-top: 52px;
            // flex-direction: column;
            // flex-wrap: nowrap;
            // align-items: flex-start;
            // justify-content: flex-end;
        }

        h2{
            background-color: #DD1134;
            padding:8px 18px;        
            color: #fff;    
            font-family: $font-dm;
            font-weight: 500;
            font-size: 22px;
            border-radius: 16px 16px 0 0;

            @include mixins.respond(tablet) {
                display: none;
            }

        }
        .btn{
            padding: 8px 18px;
            color: #0f2137;    
            font-size: 18px;
            border-radius: 16px 16px 0 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 0 24px;
            border-radius: 0px;
            @include mixins.respond(mobile) {
                width: 100%;
            }



            &:hover{
                cursor: pointer;
                background-color: #00339999!important;
                color: #fff!important;
                // border-radius: 0px;
            }

            p{
                width: 15vw;

                @include mixins.respond(tablet) {
                    width: 30vw;
                }

                @include mixins.respond(mobile) {
                    width: 100%;
                }
            }
        }
    }

    .contentContainer{
        width: 65vw;

        @include mixins.respond(tablet) {
            width: 100%;
        }

        .content{
            padding-right: 28px;
            @include mixins.respond(tablet) {
                padding-right: 0px;
                padding-left: 28px;
            }

            .content-title{
                font-family: $font-dm;
                font-weight: 600;
                font-size: 32px;
                color: #003399;
                margin-bottom: 24px;
            }
        }
    }
}

.services-ck-content{
    font-family: $font-dm!important;

    h1{
        font-size: 24px;
        color: #0f2137!important;
        padding: 8px 0;
        line-height: 150%;
        letter-spacing: 0.4px;
        font-family: $font-dm!important;
    }

    h2{
        font-size: 22px;
        color: #0f2137!important;
        padding: 8px 0;
        line-height: 150%;
        letter-spacing: 0.4px;
        font-family: $font-dm!important;
    }

    h2{
        font-size: 20px;
        color: #0f2137!important;
        padding: 8px 0;
        line-height: 150%;
        letter-spacing: 0.4px;
        font-family: $font-dm!important;
    }

    p{
        font-size: 18px;
        color: #0f2137;
        padding: 8px 0;
        line-height: 150%;
        letter-spacing: 0.4px;
        font-family: $font-dm!important;
    }

    li{
        font-size: 18px;
        color: #0f2137;
        padding: 4px 0;
        line-height: 150%;
        letter-spacing: 0.4px;
        font-family: $font-dm!important;
    }

    span{
        font-size: 18px!important;
        color: #0f2137!important;
        padding: 4px 0;
        line-height: 150%;
        letter-spacing: 0.4px;
        font-family: $font-dm!important;
    }


    .remittance-image{
        margin: 24px 0;
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 54px;

        @include mixins.respond(tablet) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            gap: 24px;
        }

        @include mixins.respond(mobile) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
            gap: 24px;
        }

        img{
            height: 100px;
            width: 250px;
            object-fit: contain;

            @include mixins.respond(tablet) {
                height: 76px;
                width: 190px;
            }

            @include mixins.respond(mobile) {

            }
        }
    }
}